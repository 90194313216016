import React, { useState } from "react";
import * as HELPER from '@src/config/helper';
import ResubmissionModal from "./ResubmissionModal";
import { requestDocResubmission, updateClaimRibbonData } from "@src/store/slices/claim";
import client from "@src/store/apolloClient";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ALLOWED_VIDEO_DURATION, DOC_VIDEO_RECORDINGS, ROLE_FEATURES, STATUS_SUB_STATUS, VIDEO_UPLOAD_LIMIT_ACCIDENT_VEHICLE_VIDEOS, ACCIDENT_VEHICLE_PHOTOS } from "@src/config/constant";
import { times } from "lodash";

export default function DocumentView(props: any) {
    let {
        documents, handleSave, setDocuments, setDeletedFiles,
        inputField, setInputField, errorField, setErrorField,
        setLoader, settlementDone, handleInputChange,
        callFrom, pendingDocuments, setPendingDocuments,
        uploadDeleteFile, pendingVideo
    } = props

    //claim_id
    const { id } = useParams();

    const [showResubmissionModal, setShowResubmissionModal] = useState(false)
    const [sliderImgs, setSliderImgs] = useState<any>([])
    const [activeSliderImg, setActiveSliderImg] = useState<any>(0)

    const dispatch: any = useDispatch()
    const claimState: any = useSelector((state: any) => state.claim)

    const disableDocUpload = callFrom === 'docs-upload' && +claimState?.ribbonData?.find((e: any) => e.key === 'status')?.value !== STATUS_SUB_STATUS['status']['application']
    const disableClaimClosing = callFrom === 'claim-closing' && +claimState?.ribbonData?.find((e: any) => e.key === 'status')?.value !== STATUS_SUB_STATUS['status']['loa-received']

    const handleImgClick = (allImages: any, activeImgIdx: number) => {
        setSliderImgs(allImages?.map((e: any) => {
            return { ...e, url: e.doc_path }
        }))
        setActiveSliderImg(activeImgIdx)
        setShowResubmissionModal(true)
    }

    const removeSelectedFile = (type: string, item: any, fileIdx: any) => {
        // if (type === 'old') {
        //     setDeletedFiles((prev: any) => ([...prev, { type: 'delete', doc_path: fileIdx.doc_path, id: fileIdx.id, document_id: fileIdx.document_id }]))
        //     let updatedDocument = documents?.map((ele: any) => {
        //         if (ele.value === fileIdx.document_id) {
        //             ele = JSON.parse(JSON.stringify(ele))
        //             ele['images'] = ele?.images?.filter((el: any) => el.doc_path !== fileIdx.doc_path)
        //         }
        //         return ele
        //     })

        //     setDocuments(updatedDocument)

        // } else {
        //     let selectedFiles = inputField;
        //     //CREATE NORMAL ARRAY & REMOVE DELETED FILE
        //     const newFileList: any = Array.from(selectedFiles[item.value]).filter((file: any, key: number) => { return key !== +fileIdx; });

        //     //CREATE FILE ARRAY
        //     let updatedDocList: any = new DataTransfer();
        //     for (let doc of newFileList) {
        //         let newFile: any = new File([doc], doc.name, { type: doc.type })
        //         updatedDocList.items.add(newFile);
        //     }

        //     selectedFiles[item.value] = updatedDocList.files.length ? updatedDocList.files : undefined;
        //     if (!selectedFiles[item.value]) delete selectedFiles[item.value]

        //     setInputField(selectedFiles)
        // }

        uploadDeleteFile(item, fileIdx, 'delete')

    }

    const checkVideoDuration = (file: any) => {
        return new Promise((resolve, reject) => {
            let duration = 0;
            const videoElement = document.createElement("video");
            videoElement.preload = "metadata";
            videoElement.src = URL.createObjectURL(file);

            videoElement.onloadedmetadata = () => {
                duration = videoElement.duration
                return resolve(+Math.ceil(+duration))
            };
        })
    }

    const checkVideoCount = async (selectedFiles: any, category: number) => {
        let count: number = 0; //considering currently uploading 1 video

        //count images in inputField
        for (let file of selectedFiles) {
            let base64File = await HELPER.toBase64(file)
            let filename = file.name
            filename = filename?.split('.')
            let fileType = (filename[filename.length - 1])?.toLowerCase()

            let isVideo = ['mp4', 'mov'].includes(fileType) || await HELPER.detectVideoMimeType(base64File, {})
            if (isVideo) count++
        }

        const files = documents?.find((e: any) => +e.value === category)?.images

        //count images in already uploaded documents 
        if (files?.length) {
            for (let file of files) {
                let image = file?.doc_path
                let splitName = image?.split('?')
                let splitPathName = splitName?.[0]?.split('.')
                let fileType = (splitPathName[splitPathName.length - 1])?.toLowerCase()

                let isVideo = ['mp4', 'mov'].includes(fileType)
                if (isVideo) count++
            }
        }

        return count

    }

    const handleFileChange = async (e: any, fieldItem: any) => {
        let selectedFile = e.target.files;

        //code to allow only video from video recordings
        let validateFile = null

        if (fieldItem.slug === 'video_recordings') {
            validateFile = await HELPER.validateVideoInput(selectedFile)
            for (let file of selectedFile) {
                let videoDuration: any = await checkVideoDuration(file)
                if (videoDuration > ALLOWED_VIDEO_DURATION) {
                    validateFile = true
                    toast.error(`Duration of the video should be within ${ALLOWED_VIDEO_DURATION} seconds`)
                    break
                }
            }
        }

        else if (fieldItem.slug === 'accident_vehicle_photo_video') {
            setLoader(true)
            let videoCount: number = 0;
            const validMimeTypes = ["video/mp4", "video/quicktime"]

            for (let file of selectedFile) {
                let base64File = await HELPER.toBase64(file)
                let isValidImage = await HELPER.detectMimeType(base64File, {}) // check if file is a valid img

                if (!isValidImage) {
                    let isValidVideo = await HELPER.detectVideoMimeType(base64File, {}) // check if file is valid video
                    if (!isValidVideo && !validMimeTypes.includes(file.type)) {
                        validateFile = true
                        toast.error('File Format is not supported')
                        break
                    }
                    let videoDuration: any = await checkVideoDuration(file)
                    if (videoDuration > ALLOWED_VIDEO_DURATION) {
                        validateFile = true
                        toast.error(`Duration of the video should be within ${ALLOWED_VIDEO_DURATION} seconds`)
                        break
                    }
                    // videoCount++;
                }
            }

            if (!validateFile) {
                let existingVideoCount = await checkVideoCount(selectedFile, fieldItem.value)

                if (existingVideoCount > VIDEO_UPLOAD_LIMIT_ACCIDENT_VEHICLE_VIDEOS) {
                    validateFile = true
                    toast.error(`Maximum ${VIDEO_UPLOAD_LIMIT_ACCIDENT_VEHICLE_VIDEOS} Videos are allowed`)
                }

                if (+pendingVideo[fieldItem.value] >= VIDEO_UPLOAD_LIMIT_ACCIDENT_VEHICLE_VIDEOS) {
                    validateFile = true
                    toast.error("Please wait while existing videos are uploading...")
                }
            }
            setLoader(false)
        }

        else {
            validateFile = await HELPER.validateFileInput(selectedFile, [])
        }

        if (validateFile) {
            e.target.value = null
            return
        }

        const maxSelectedFiles = inputField[fieldItem.value] ? Array.from(inputField[fieldItem.value]).concat(Array.from(selectedFile)) : selectedFile;
        const alreadyExistingFiles = documents?.find((e: any) => e.value === fieldItem.value)?.images || []

        if (fieldItem.max_document) {
            if ((maxSelectedFiles.length + alreadyExistingFiles.length) > fieldItem.max_document) {
                setErrorField({ ...errorField, [fieldItem.value]: 'Maximum 5 Documents are allowed' });
                e.target.value = null
                setInputField({ ...inputField, [fieldItem.value]: '' });

            } else {
                setInputField({ ...inputField, [fieldItem.value]: maxSelectedFiles });
                setErrorField({ ...errorField, [fieldItem.value]: '' })
                uploadDeleteFile(fieldItem, selectedFile)
            }
        }
    }

    const handleDocumentResubmission = async (comment: any, document_id: any) => {
        setLoader(true)
        try {
            await callResubmissionApi(comment, document_id)
        } finally {
            setLoader(false)
        }
    }

    const callResubmissionApi = async (comment: any, document_id: any) => {
        let variables = {
            api_type: 'docUpload',
            claim_id: id,
            comment,
            document_id
        }
        const result: any = await requestDocResubmission(client, variables)
        if (result?.status === 200) {
            let docStatusId = +result?.data
            let isDocStatusChanged = false
            let updatedRibbonData = claimState?.ribbonData?.map((e: any) => {
                if (e.key === 'doc_status') {
                    isDocStatusChanged = e.value === docStatusId ? false : true
                    return { ...e, value: docStatusId } // update doc status
                }
                return e
            })

            //mark images resubmit
            let updatedDocuments = documents?.map((e: any) => {
                let value = { ...e }
                if (value?.value === +document_id) {
                    value.images = value?.images?.map((el: any) => ({ ...el, is_resubmit: 1 }))
                    return value
                }
                return { ...e }
            })

            setDocuments(updatedDocuments)

            //update doc status only if difference is found
            if (isDocStatusChanged) {
                dispatch(updateClaimRibbonData(updatedRibbonData)) //update doc status in ribbon
            }

            //update requested documents list
            let updatedPendingDocs = [...pendingDocuments]
            if (!pendingDocuments.find((e: any) => e.doc_id === +document_id)) {
                updatedPendingDocs.push({
                    doc_id: +document_id,
                    name: documents?.find((e: any) => e.value === +document_id)?.label
                })
            }

            if (updatedPendingDocs?.length) setPendingDocuments(updatedPendingDocs)

            toast.success('success')
            setShowResubmissionModal(false)
        } else {
            toast.error("Error")
        }

    }

    return (
        <>
            {
                showResubmissionModal
                    ?
                    <ResubmissionModal showModal={showResubmissionModal} sliderImages={sliderImgs} activeImageIndex={activeSliderImg} closeResubmitModal={() => setShowResubmissionModal(false)} handleResubmitDocTrigger={(comment: any, document_id: any) => handleDocumentResubmission(comment, document_id)} status={claimState?.claimDetails?.status_id} />
                    : undefined
            }
            {
                documents?.map((doc: any, idx: number) => {
                    const isVideoRecordingDoc = doc?.value === DOC_VIDEO_RECORDINGS
                    const isAccidentVehiclePhotoVideos = doc?.value === ACCIDENT_VEHICLE_PHOTOS

                    let imageCount = doc?.images?.length + (inputField[doc?.value] ? Object.keys(inputField?.[doc?.value])?.length : 0)

                    return (
                        <React.Fragment key={doc?.slug + "_" + idx}>
                            <div className='doc-upload-main'>
                                <span className='doc-heading'>
                                    {doc?.label + (doc.is_required ? '*' : '')}
                                    {
                                        (errorField[doc.value]) ?
                                            <span className="error-txt">{" " + errorField[doc.value]}</span>
                                            : null
                                    }
                                </span>
                                <div className='upload-doc-img'>
                                    {
                                        doc?.images?.map((img: any, idx: number) => {
                                            let image = img.doc_path
                                            let splitName = img?.doc_path?.split('?')
                                            let splitPathName = splitName?.[0]?.split('.')
                                            let fileType = (splitPathName[splitPathName.length - 1])?.toLowerCase()
                                            fileType = fileType === 'csv' ? 'excel' : (fileType === 'doc' || fileType === 'docx') ? fileType = 'word' : fileType

                                            return (
                                                <React.Fragment key={img.document_id + "_default_idx_" + idx}>
                                                    <div className="img-bx-select-opt">
                                                        <div className="image-bx" onClick={(e: any) => handleImgClick(doc.images, idx)}>
                                                            {
                                                                fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' ?
                                                                    <img src={image} alt={"document_image_" + idx} />
                                                                    :
                                                                    fileType === 'mp4' || fileType === 'mov'
                                                                        ?
                                                                        <img src={'/play3.svg'} className='detail-video-icon-size' alt={`video-${idx}-play-icon`} />
                                                                        :
                                                                        <a href={image} target="_blank" rel="noreferrer"><i className={`ic-${fileType}-file icons-normal`}></i></a>
                                                            }
                                                            {
                                                                img?.is_resubmit === 1
                                                                    ?
                                                                    <span className="image-requested">Resubmission Requested</span>
                                                                    : undefined
                                                            }
                                                        </div>
                                                        {
                                                            (disableDocUpload || disableClaimClosing)
                                                                ?
                                                                undefined
                                                                :
                                                                <div className="close-icn icon-top-right" onClick={() => removeSelectedFile('old', doc, img)}>
                                                                    <i className="ic-clearclose"></i>
                                                                </div>
                                                        }
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                    {/* {
                                        inputField[doc?.value] ? Object.keys(inputField?.[doc?.value])?.map((item: any, idx: number) => {
                                            const file = inputField[doc.value][item]
                                            let fileType = file?.name?.split('.')
                                            fileType = fileType && fileType[fileType?.length - 1]
                                            fileType = fileType && fileType.toLowerCase()
                                            fileType = fileType === 'csv' ? 'excel' : (fileType === 'doc' || fileType === 'docx') ? fileType = 'word' : fileType

                                            return <React.Fragment key={item + "_idx_" + idx}>
                                                <div className="img-bx-select-opt">
                                                    <div className="image-bx">
                                                        {
                                                            fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' ?
                                                                <img src={URL.createObjectURL(file)} className="" alt={`image-${idx}`} />
                                                                :
                                                                ['mp4', 'mov'].includes(fileType)
                                                                    ?
                                                                    <img src={'/play3.svg'} className='detail-video-icon-size' alt={`video-${idx}`} />
                                                                    :
                                                                    <a href={URL.createObjectURL(file)} target="_blank" rel="noreferrer"><i className={`ic-${fileType}-file icons-normal`}></i></a>
                                                        }
                                                    </div>
                                                    {
                                                        (disableDocUpload || disableClaimClosing)
                                                            ?
                                                            undefined
                                                            :
                                                            <div className="close-icn icon-top-right" onClick={() => removeSelectedFile('new', doc, item)}>
                                                                <i className="ic-clearclose"></i>
                                                            </div>
                                                    }

                                                </div>
                                            </React.Fragment>
                                        })
                                            : undefined
                                    } */}

                                    {
                                        pendingVideo?.[doc?.value]
                                            ?

                                            times(pendingVideo[doc?.value], (idx: number) => {
                                                return (
                                                    <React.Fragment key={`video-${idx}`}>
                                                        <div className="img-bx-select-opt">
                                                            <div className="image-bx">
                                                                <img src={'/play3.svg'} className='detail-video-icon-size' alt='video-play-icon' />
                                                                <div className="video-loader"></div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })

                                            : undefined
                                    }

                                    {
                                        (disableDocUpload || disableClaimClosing || imageCount === doc.max_document)
                                            ?
                                            undefined
                                            :

                                            <div className="img-bx-select-opt">
                                                <div className="image-bx more-file-upload">
                                                    <i className='ic-add'></i>
                                                    <input type="file" onChange={(e) => handleFileChange(e, doc)} />
                                                    <p>{isAccidentVehiclePhotoVideos ? 'Add Photo/Video' : isVideoRecordingDoc ? 'Add Video' : 'Add Photo'}</p>
                                                </div>
                                            </div>
                                    }

                                </div>
                            </div>
                        </React.Fragment>
                    )
                })
            }

            {
                settlementDone
                    ?
                    <fieldset className="form-filed">
                        <div className="material">
                            <textarea className='form-input' placeholder=' ' onChange={(e: any) => handleInputChange(e.target.value, 'remarks')} value={inputField?.['remarks'] || ''}></textarea>
                            <label data-label="Remarks" className="form-label"></label>
                        </div>
                    </fieldset>
                    : undefined
            }

            <div className="btn-continue">
                {
                    (!HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['update_claim_application'], accessType: 'edit', assignUserId: claimState?.claimDetails?.assign_user_id }) || disableDocUpload || disableClaimClosing)
                        ?
                        <button type="button" className="btn-primary" disabled>Continue</button>
                        :
                        <button type="button" className="btn-primary" onClick={handleSave}>Continue</button>
                }
            </div>
        </>
    )
}