import React, { useEffect, useState } from 'react';
import useClaimProcessingData from '@src/hooks/useClaimProcessingData';
import { useDispatch, useSelector } from 'react-redux';
import { UPLOAD_FILE } from '@src/services/claimdata.gql';
import { print } from 'graphql'
import { executeUpload } from '@src/common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import { useSearchParams, useParams } from 'react-router-dom';
import { STATUS_SUB_STATUS, EMAIL, SEND_TO_CUST_DOCS, ROLE_FEATURES } from '@src/config/constant';
import { updateClaimDetailsInStore, updateClaimHistory, updateClaimRibbonData, fetchClaimProcessingData, saveDocUploadClaimClosingSection } from '@store/slices/claim';
//import { customerTemplate, saveCustomerTemplateData,templateFieldInfo, updateTemplateValue,getRequestedDocuments } from '@store/slices/template';
import { getRequestedDocuments } from '@store/slices/template';
import { WHATSAPP_WEB_URL } from '@src/config/constant'
import FullPageLoader from '@src/view/common/FullPageLoader'
import DocumentView from '@src/common/DocumentView';
import PendingDocumentPopup from '../PendingDocumentPopup';
import AskToCustomerPopup from '@common/AskToCustomerPopup';
import * as HELPER from '@src/config/helper';
import client from '@store/apolloClient';

export default function DocumentsUpload(props: any) {
    const [inputField, setInputField] = useState<any>({})
    const [AskCustomer, setAskCustomer] = useState(false)
    const [documents, setDocuments] = useState<Array<any>>([])
    const [searchParams, setSearchParams] = useSearchParams();
    const [formData, setFormData] = useState<any>({
        doc_id: [],
        template_type: EMAIL,
        description: ''
    });
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false)
    const [showConfirmPopup, setShowConfirmPopup] = useState(false)
    const [pendingDocuments, setPendingDocuments] = useState<Array<any>>([])
    const [pendingVideo, setPendingVideo] = useState<any>({})

    // claim id
    const { id } = useParams();

    const [deletedFiles, setDeletedFiles] = useState<Array<any>>([])
    const [errorField, setErrorField] = useState<Array<any>>([])
    const partnerTemplate = useSelector((state: any) => state.template)
    const dispatch: any = useDispatch()

    const claimState = useSelector((state: any) => state.claim)
    const templateinfo = useSelector((state: any) => state.template)
    const [claimData, setClaimData] = useState<any>();

    useClaimProcessingData({ type: 'docUpload', api_type: 'docUpload', call_from: 'docUpload' })

    const showAskCustomerPopup = () => {
        setAskCustomer(true)
        document.body.classList.add("overflow-hidden");
    }


    const fetchCustomerPendingDocuments = async () => {
        const result: any = await getRequestedDocuments({ claim_id: id, key: 'askToCustomer' })
        setPendingDocuments(result?.data || [])
    }

    const handleContinueClick = () => {
        if (pendingDocuments?.length) {
            setShowConfirmPopup(true)
        } else {
            handleSave()
        }
    }

    const validateErr = () => {
        let errorOb: any = {}
        let isError = true;
        let notUploadedDocs = []

        for (let ob of documents) {
            if (ob.is_required && !ob?.images?.find((e: any) => !e.is_resubmit || e.is_resubmit === 0)) {
                isError = false;
                errorOb[ob.value] = `${ob.label} Field is required`;
                notUploadedDocs.push(ob.label)
            }
        }

        setErrorField((prev) => ({ ...prev, ...errorOb }));
        return { isError, notUploadedDocs };
    }

    const uploadDeleteDocuments = async (fieldItem: any, file: any, type?: string) => {
        props.setLoader(true)

        let isVideo = false
        let curVideoCount: any = null

        let docDetails: any = { document_id: +fieldItem.value, type: type ?? 'add' }
        //case when customer is deleting
        if (type === 'delete') docDetails['id'] = file.id

        let finalDocuments: any = [{ ...docDetails }]
        let filesArray: any = type === 'delete' ? [] : [file[0]]

        if (type !== 'delete') {
            let fileName = filesArray?.[0]?.name
            let extension = fileName?.split('.')
            extension = extension?.[extension.length - 1]?.toLowerCase()
            isVideo = ['mp4', 'mov'].includes(extension)

            if (isVideo) {
                props.setLoader(false);
                setPendingVideo((prev: any) => {
                    curVideoCount = (pendingVideo[fieldItem.value] || 0) + 1
                    return { ...prev, [fieldItem.value]: curVideoCount }
                })
            }
        }

        const formData = new FormData();
        const operations = JSON.stringify({
            query: print(UPLOAD_FILE()),
            variables: {
                file: filesArray.map(() => null),
                claim_id: id,
                documents: JSON.stringify(finalDocuments),
                api_type: 1 //representing document upload section
            }
        });

        const map: any = {};
        filesArray.forEach((_: any, index: any) => {
            map[index] = [`variables.file.${index}`];
        });

        formData.append('operations', operations);
        formData.append('map', JSON.stringify(map));

        filesArray.forEach((file: any, index: any) => {
            formData.append(index.toString(), file);
        });

        try {
            const result = await executeUpload(formData)
            let { status, data } = result?.data?.uploadFile

            if (status && status === 200) {
                let updatedRibbonData = claimState?.ribbonData?.map((e: any) => {
                    if (e.key === 'doc_status') {
                        return { ...e, value: data?.doc_status_id } // update doc status as it is always going to be 2 after this status
                    }
                    return e
                })

                if (data?.addedDocument?.length) {
                    let docCategory = +fieldItem?.value
                    let updatedDocs = documents?.map((e: any) => {
                        if (e.value !== docCategory) return e

                        let updatedItem = { ...e }
                        let updatedImgs = [...data.addedDocument]
                        if (updatedItem?.images?.length) {
                            updatedImgs = [...updatedItem?.images, ...updatedImgs]
                        }

                        return { ...updatedItem, images: updatedImgs }
                    })

                    setDocuments(updatedDocs)
                    toast.success("File uploaded successfully")
                    setRefresh(!refresh)

                }

                if (data?.deletedDocument?.length) {
                    let updatedDocuments = documents?.map((e: any) => {
                        let doc = { ...e }
                        if (e.value === +fieldItem.value) {
                            doc['images'] = doc['images']?.filter((el: any) => el.id !== file?.id)
                            return doc
                        } else {
                            return doc
                        }
                    })

                    setDocuments(updatedDocuments)
                    toast.success("File Deleted Successfully")
                }

                dispatch(updateClaimRibbonData(updatedRibbonData)) //update claim status in ribbon
                setInputField({})
                setDeletedFiles([])
                // setSearchParams(`?${new URLSearchParams({ type: 'send-insurance' })}`)
            } else {
                let error = result?.error || "Error in Uploading file"
                toast.error(error)
            }
        } catch {

        } finally {
            if (isVideo) {
                setPendingVideo((prev: any) => {
                    let updated = { ...prev }
                    let currCount = (curVideoCount || 0) - 1

                    if (currCount <= 0) delete updated?.[fieldItem.value]
                    else prev[fieldItem.value] = currCount

                    return updated
                })
            }
            setRefresh(!refresh)
            props.setLoader(false);
            // setShowConfirmPopup(false)
        }
    }

    // const handleSave = async () => {
    //     props.setLoader(true)

    //     const { isError, notUploadedDocs } = validateErr()
    //     if (!isError) {
    //         props.setLoader(false)
    //         toast.error(`Please upload ${notUploadedDocs.toString()}`)
    //         return false
    //     }

    //     let finalDocuments: any = [...deletedFiles]
    //     let filesArray: any = []

    //     for (let document in inputField) {
    //         for (let img of inputField[document]) {
    //             let obj = {
    //                 document_id: +document,
    //                 type: 'add'
    //             }
    //             finalDocuments.push(obj)
    //             filesArray.push(img)
    //         }
    //     }

    //     const formData = new FormData();
    //     // const status = claimState?.ribbonData?.find((e: any) => e.key === 'status')?.value || 0
    //     const isNewCreated = true//+status < STATUS_SUB_STATUS['status']['document-uploaded'] ? !documents?.some((e: any) => e?.images?.filter((el: any) => el.source === 1)?.length) : false

    //     const operations = JSON.stringify({
    //         query: print(UPLOAD_FILE()),
    //         variables: {
    //             file: filesArray.map(() => null),
    //             claim_id: id,
    //             documents: JSON.stringify(finalDocuments),
    //             is_new_created: isNewCreated
    //         }
    //     });

    //     const map: any = {};
    //     filesArray.forEach((_: any, index: any) => {
    //         map[index] = [`variables.file.${index}`];
    //     });

    //     formData.append('operations', operations);
    //     formData.append('map', JSON.stringify(map));

    //     filesArray.forEach((file: any, index: any) => {
    //         formData.append(index.toString(), file);
    //     });

    //     try {
    //         const result = await executeUpload(formData)
    //         let { status, data } = result?.data?.uploadFile

    //         if (status && status === 200) {
    //             let updatedRibbonData = claimState?.ribbonData?.map((e: any) => {
    //                 if (e.key === 'status') {
    //                     return { ...e, value: data.status_id }
    //                 }

    //                 if (e.key === 'doc_status') {
    //                     return { ...e, value: data?.doc_status_id } // update doc status as it is always going to be 2 after this status
    //                 }
    //                 return e
    //             })

    //             let updatedHistory = claimState?.claimHistory?.includes(data.status_id) ? [] : [...claimState?.claimHistory, data.status_id]

    //             dispatch(updateClaimDetailsInStore({ ...claimState?.claimDetails, status_id: data.status_id }))
    //             dispatch(updateClaimRibbonData(updatedRibbonData)) //update claim status in ribbon
    //             if (updatedHistory.length) dispatch(updateClaimHistory(updatedHistory)) //update claim status history
    //             setInputField({})
    //             setDeletedFiles([])
    //             setSearchParams(`?${new URLSearchParams({ type: 'send-insurance' })}`)
    //             toast.success("Data saved successfully")
    //         }
    //     } catch {

    //     } finally {
    //         props.setLoader(false);
    //         setShowConfirmPopup(false)
    //     }
    // }

    const handleSave = async () => {
        props.setLoader(true)

        const { isError, notUploadedDocs } = validateErr()
        if (!isError) {
            props.setLoader(false)
            toast.error(`Please upload ${notUploadedDocs.toString()}`)
            return false
        }

        try {
            let options = {
                claim_id: id,
                type: 1, //document section
                claim_closing_details: null
            }

            const result: any = await saveDocUploadClaimClosingSection(client, options)
            if (result && result?.status === 200) {
                const { data } = result
                let updatedRibbonData = claimState?.ribbonData?.map((e: any) => {
                    if (e.key === 'status') {
                        return { ...e, value: data.status_id }
                    }
                    return e
                })

                let updatedHistory = claimState?.claimHistory?.includes(data.status_id) ? [] : [...claimState?.claimHistory, data.status_id]

                dispatch(updateClaimDetailsInStore({ ...claimState?.claimDetails, status_id: data.status_id }))
                dispatch(updateClaimRibbonData(updatedRibbonData)) //update claim status in ribbon
                if (updatedHistory.length) dispatch(updateClaimHistory(updatedHistory)) //update claim status history
                setDeletedFiles([])
                setSearchParams(`?${new URLSearchParams({ type: 'send-insurance' })}`)
                toast.success("Data saved successfully")
            } else {
                toast.error("Error Occured")
            }
        } catch (error) {

        } finally {
            props.setLoader(false);
            setShowConfirmPopup(false)
        }
    }

    useEffect(() => {
        let { documents } = claimState?.claimDetails || {}
        if (documents?.length) {
            setDocuments(documents)
        }
    }, [claimState?.claimDetails?.documents])

    useEffect(() => {
        let { claimDetails } = claimState
        if (claimDetails.hasOwnProperty('loading')) {
            setLoading(false)
        }
        else setLoading(true)
    }, [claimState?.claimDetails])

    useEffect(() => {
        //fetch pending documents
        fetchCustomerPendingDocuments()
    }, [])

    // if claimDetails is not present in store then call api for fetch calim details
    useEffect(() => {
        if (claimState?.claimDetails?.partner_hash_id) {
            setClaimData(claimState?.claimDetails);
        } else {
            fetchClaimProcessingData({ type: "applicationDetails", variables: { claim_id: id, api_type: "applicationDetails" } }).then((res: any) => {
                if (res?.data) {
                    dispatch(updateClaimDetailsInStore({ ...claimState?.claimDetails, ...res.data }));
                    setClaimData(res?.data)
                }

            })
        }
    }, [])

    return (
        <div className="form-tab-right-panel">
            <FullPageLoader show={loading} />
            <div className="lead-detail-from-outer">
                <div className="lead-form-heading  lead-top-heading">
                    <h2>Documents Upload</h2>
                    {
                        (!HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['update_claim_application'], accessType: 'edit', assignUserId: claimState?.claimDetails?.assign_user_id }) || +claimState?.ribbonData?.find((e: any) => e.key === 'status')?.value !== STATUS_SUB_STATUS['status']['application'])
                            ?
                            <button className='btn-line ask-coustmer-btn' disabled style={{ cursor: 'pointer' }}>
                                <i className='ic-upload'></i>
                                Ask Customer to Upload Docs
                            </button>
                            : <button className='btn-line ask-coustmer-btn' onClick={showAskCustomerPopup}>
                                <i className='ic-upload'></i>
                                Ask Customer to Upload Docs
                            </button>
                    }

                </div>
                <div className="lead-form-filed p-lg-t">
                    <DocumentView documents={documents} setDocuments={setDocuments} handleSave={handleContinueClick} setDeletedFiles={setDeletedFiles}
                        inputField={inputField} setInputField={setInputField} errorField={errorField} setErrorField={setErrorField}
                        setLoader={setLoading} callFrom={"docs-upload"} pendingDocuments={pendingDocuments} setPendingDocuments={setPendingDocuments} uploadDeleteFile={uploadDeleteDocuments} pendingVideo={pendingVideo} />

                    {
                        showConfirmPopup
                            ?
                            <PendingDocumentPopup show={showConfirmPopup} list={pendingDocuments} close={() => setShowConfirmPopup(false)} submit={handleSave} />
                            : undefined
                    }
                </div>
            </div>

            {
                AskCustomer &&
                <AskToCustomerPopup
                    formData={formData}
                    documents={documents}
                    setFormData={setFormData}
                    setLoading={setLoading}
                    askCustomer={AskCustomer}
                    setAskCustomer={setAskCustomer}
                    keys="askToCustomer"
                    template_for={SEND_TO_CUST_DOCS}
                    claimData={claimData}
                    pendingDocuments={pendingDocuments}
                    setPendingDocuments={setPendingDocuments}
                />
            }

        </div>
    )
}