import { gql } from '@apollo/client';


export const GET_SHOP_LIST = (partner_id) => {
    return gql`query{
        getListRepairShop(partner_id:${partner_id}){
            status
            message
            data {
                label
                value
            }
        }
    }`
};


export const GET_SHOP_REGION_LIST = (shop_id,partner_id) => {
    return gql`query{
        getListShopRegion(shop_id:${shop_id},partner_id:${partner_id}){
            status
            message
            data {
                label
                value
                status
                shop_id
            }
        }
    }`
};


export const GET_EVALUATION_DATA = (claim_id) => {
    return gql`query{
        getEvaluationData(claim_id:"${claim_id}"){
            status
            message
            data {
                id
                claim_id
                cash_settlement
                shop_id
                region_id
                is_shop_accredited
                eval_details{
                    id
                    ev_amount
                    ev_datetime
                    ev_type
                    rowId
                    img_name
                    url
                }
            }
        }
    }`
};

export const SAVE_EVALUATION_DATA = gql`mutation save_evaluation($saveEvalInput:SaveEvalInput!) {
    save_evaluation(saveEvalInput: $saveEvalInput) {
           status
           message
            data {
                status_id
            }
        
   }
}`


export const UPLOAD_EVAL_FILE = () => {
    return gql
    ` mutation ($file: [Upload!], $claim_id: String!) {
        uploadEvalFile(fileInput: {images: $file, claim_id: $claim_id}) {
            data
            status
            message
        }
    }`
}


export const GET_REPAIR_DOC = (claim_id,doc_id) => {
    return gql`query{
        getRepairDocument(claim_id:"${claim_id}",doc_id:${doc_id}){
            status
            message
            data{
                doc_path
                url
            }
        }
    }`
};


