import React, { Children, Component, useEffect, useState } from 'react';
import { Button, Dropdown, Tab, Tabs, Nav } from 'react-bootstrap';
// import ClaimListFilter from "./ClaimListFilter"
// import DocNotification from "../component/DocumentNotification";
// import ClaimTable from "../component/ClaimListTable";
import Pagination from '../../common/Pagination';
import Select, { components } from 'react-select';
import { GET_USER_LIST } from "../../../services/user.gql";
import { executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import { useApolloClient } from '@apollo/client';
import { getUserListing } from '../../../store/slices/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
import FullPageLoader from '../../common/FullPageLoader';
import AddUserPopup from './AddUserPopup';
import UserListPopup from './UserListEdit';
import ConfirmPopup from '@src/view/common/ConfirmPopUp';
import { getMasterConfig } from '@store/slices/common';
import { getRoleListing,getFeaturesData, updateStatus } from '@store/slices/RoleUserSlice';
import { deleteUser } from '@store/slices/UserSlice';
import { toast } from 'react-toastify';
import * as HELPER from '@src/config/helper';
import { ROLE_FEATURES } from '@src/config/constant';

const { ValueContainer, Placeholder } = components;


const UserList = () => {

    const client = useApolloClient();


    const [searchParams, setSearchParams] = useState<any>({  page_number: 1  });

    useEffect(() => {
        // let updatedFilters = searchParams;

        // executeGraphQLQuery(GET_USER_LIST(), client, updatedFilters).then((response: any) => {
        //     // setLoading(false);
        //     console.log("result")

        //     if (response && response.data && response.data.userList) {
        //         let result = response?.data?.userList?.data;
        //         console.log(result)
        //         setUsersList(result);
        //         // getIncentiveData(result,activeTab);
        //     }
        // }).catch((error: any) => {
        //     console.log("error",error)

        // });
    }, [])

    // const changeHandler = (even:any) => {
    //     setSearchParams({filter_list: {...searchParams.filter_list, searchKeyword: even.target.value }})
    // } 

    // /**
    //  * ON ENTER SEARCH 
    //  * @param event 
    //  */
    // const handleKeyDown = (event: any) => {
    //     if (event.key === 'Enter') {

    //         // getUserManageListDetails({...filterData, searchKeyword: searchText?.trim() || undefined, page_number: 1});
    //     }
    // }


    const dispatch: any = useDispatch();
    let [userManageList, setUserManageList] = useState<any>([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);
    const [showUserPopup, setUserPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [paginationData, setPaginationData] = useState<any>({});
    let [searchText, setSearchText] = useState('');
    let [activeTab, setActiveTab] = useState(1);
    const [showUserRole, setShowUserRole] = useState(false);
    let [roleList, setRoleList] = useState<any>([]);
    let [userDataToEdit, setUserDataToEdit] = useState<any>({});
    let [tabCount, setTabCount] = useState<any>({});
    let [filterData, setFilterData] = useState<any>({});
    const [masterData, setMasterData] = useState<any>({})

    const roleUserState = useSelector((state: any) => state.roleUser);
    const commonState = useSelector((state: any) => state.common);
    
    useEffect(() => {
        if (Object.keys(commonState.common).length === 0) {
        dispatch(getMasterConfig());
        }
        //eslint-disable-next-line
        fetchMasterData()
    }, []);

    // let vertical_list = commonState?.common?.vertical_list??[];

    const getUserManageListDetails = async (params: any) => {
        let filters: any = {
            ...searchParams, ...params
        }
        
        setFilterData(filters);
        setLoading(true);
        dispatch(getUserListing({ 'filter_list': { ...filters } }))
            .then((res: any) => {
                setLoading(false);
                if (res?.status === 200) {
                    res.data = res?.data?.map((data: any) => {
                        let { password, ...rest } = data;
                        //  let assignedRole = [];
                        return rest
                    });   /// Remove password by default 
                    setPaginationData({ ...res?.pagination });
                    setUserManageList([...res?.data]);
                    setTabCount({ ...res?.payload?.tabCount });
                    //setUsersList([...res?.data]);
                }

            }).catch((err: Error) => {
                setLoading(false);
            })
    };



    const getRoleListDetails = async (params: any) => {
        // let filters = {
        //     page_number: params?.page_number ?? 1
        // }
        // getRoleListing(filters).then((res: any) => {
        dispatch(getRoleListing({ 'filter_list': {  } }))
            .then((res: any) => {
            if (res && res.status === 200 && res.data) {
                roleList = res.data.filter((e: any) => +e.status === 1).map((e: any) => {
                    return {
                        value: e.id,
                        label: e.roleName
                    }
                })
                setRoleList([...roleList]);
            }
            setLoading(false);
        }).catch((err: Error) => {
            setLoading(false);
        })
    };


    const searchUser = (event: any) => {

        let value = event.target.value;
        setSearchText(value);
    }

    const addNewUser = (action: boolean = true) => {
        setShowUserRole(action);
        document.body.classList.remove("overflow-hidden");

        if (action) {
            document.body.classList.add("overflow-hidden");
        }
    }

    const showDeletePopUp = async (action: boolean, id: any) => {
        setIdToDelete(id);
        setShowConfirmation(action);
    }

    const confirmYes = async () => {
        deleteUser({ user_input: { id: idToDelete, is_delete: 1} }).then((res: any) => {
            if (res && res.status === 200) {
                toast.success(res.message)
                getUserManageListDetails({});
            }
        });
        confirmNo();
    };

    const confirmNo = () => {
        setShowConfirmation(false);
    };

    const userEditPopup = (action: boolean, userData: any) => {
        setUserPopup(action);
        action ? setUserDataToEdit(userData) : setUserDataToEdit({});
        document.body.classList.remove("overflow-hidden");
        if (action) {
            document.body.classList.add("overflow-hidden");
        }
    }

    const handleRequestData = (params: any) => {
        let apiParams = {
            page_number: params.page_no
        }

        getUserManageListDetails({ ...filterData, ...apiParams });

    }

    const getApiData = async () => {
        await getUserManageListDetails({});
        await getRoleListDetails({});
    }

    useEffect(() => {
        getApiData();
        // eslint-disable-next-line
    }, []);

    /**
     * ON ENTER SEARCH 
     * @param event 
     */
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {

            getUserManageListDetails({ ...filterData, searchKeyword: searchText?.trim() || undefined, page_number: 1 });
        }
    }


    const fetchMasterData = async () => {
        let data: any = null
        if (commonState?.common && Object.keys(commonState.common || {})?.length) {
            data = commonState.common
        } else {
            data = await dispatch(getMasterConfig())
           
        }

        setMasterData(data)
    }
   
    return (
        <>
            < FullPageLoader show={loading} />
            <div className="top-filter-bg user-management-inner-tabs">
                <div className="selected-chips user-status-tab-chips">
                    <Tab.Container id="left-tabs-example" >

                        <Nav variant="pills" className="flex-column  nav nav-pills">
                            <div className="tab-list">
                                <Nav.Item>
                                    <Nav.Link className={activeTab === 1 ? "active" : ''} onClick={() => { setActiveTab(1); getUserManageListDetails({ ...filterData, page_number: 1, status: undefined }) }}>All Users <span className='chip-count-span'>{paginationData?.count_all_user || 0}</span> </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className={activeTab === 2 ? "active" : ''} onClick={() => { setActiveTab(2); getUserManageListDetails({ ...filterData, page_number: 1, status: 1 }) }}>Active Users <span className='chip-count-span'>{paginationData.count_active_user || 0}</span> </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className={activeTab === 3 ? "active" : ''} onClick={() => { setActiveTab(3); getUserManageListDetails({ ...filterData, page_number: 1, status: 0 }) }}>Inactive Users <span className='chip-count-span'>{paginationData.count_inactive_user || 0}</span></Nav.Link>
                                </Nav.Item>
                            </div>
                            <div className='search-user-add-btn'>
                                <fieldset className="search-bx">
                                    <div className="material">
                                        <input id="Search" type="text" placeholder="Search User by name, email, mobile..." onChange={(e: any) => searchUser(e)} value={searchText} className="form-input" onKeyDown={handleKeyDown} />
                                        <i className="ic-search"></i>
                                    </div>
                                </fieldset>
                                {
                                    HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['user_role_management'], accessType: 'edit' }) &&
                                    <button className="btn-line" onClick={() => addNewUser(true)}>
                                        <span>Add User</span>
                                    </button>
                                }
                            </div>
                        </Nav>
                    </Tab.Container>

                </div>
            </div>

            <div className='data-table'>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            {/* <th>Type</th> */}
                            {/* <th>Assigned To</th> */}
                            <th>Status</th>
                            <th>Edit</th>
                            {
                                HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['user_role_management'], accessType: 'edit' }) &&
                                <th>Delete</th>

                            }
                        </tr>
                    </thead>
                    <tbody>

                        {
                            userManageList?.length
                                ?
                                userManageList.map((item: any, index: number) =>
                                    <tr key={index}>
                                        <td>{item?.id}</td>
                                        <td>{item?.name}</td>
                                        <td><span className='truncate' title = {`${item?.email}`}>{item?.email}</span></td>
                                        <td>{item?.mobile}</td>
                                        <td><span className={item?.status ? "active-dot" : "inactive-dot"}>{item?.status ? "Active" : "In Active"}</span></td>
                                        
                                        {/* <td>CTPL</td> */}
                                        {/* <td>Ramesh Singh</td> */}
                                        <td>
                                        <ul className="action-btn">
                                            <li onClick={() => userEditPopup(true, item)}>
                                                <i className="ic-edit"></i>
                                            </li>
                                        </ul>

                                    </td>
                                    {
                                        HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['user_role_management'], accessType: 'edit' })
                                        && 
                                        <td className="action-btn">
                                            <ul className="action-btn">
                                                <li onClick={() => showDeletePopUp(true, item.id)}>
                                                    <i className="ic-delete"></i>
                                                </li>
                                            </ul>
                                        </td>
                                    }
                                    
                                    </tr>
                                )
                                :
                                <tr><td className='no-data' colSpan={7}>No Data Found</td></tr>
                        }
                    </tbody>
                </table>


                {(paginationData && paginationData['total'] > 10) && <Pagination paginateData={{
                        page: 1,
                        loading: false,
                        totalLengthWithoutPagination: paginationData['total'],
                        paginationData,
                        filteredData: filterData
                    }}
                        sendRequestDataToParent={handleRequestData} />}

                    {showUserPopup && <UserListPopup
                        showUserPopup={showUserPopup}
                        userEditPopup={(e: boolean) => {
                            userEditPopup(e, {});
                        }}
                        userDetails={userDataToEdit}
                        role_list={roleList}
                        setLoading={setLoading}
                        getUserManageListDetails={(e: any) => {
                            getUserManageListDetails(e)
                        }} 
                        setSearchText = {setSearchText}
                        />}

                    {showUserRole && <AddUserPopup
                        showUserRole={showUserRole}
                        role_list={roleList}
                        setLoading={setLoading}
                        addNewUser={(e: boolean) => {
                            addNewUser(e);
                        }}
                        getUserManageListDetails={(e: any) => {
                            getUserManageListDetails(e)
                        }} 
                        setSearchText = {setSearchText}
                        />}
                    {showConfirmation === false ? ("") : (
                        <ConfirmPopup
                            confirmNo={confirmNo}
                            confirmYes={confirmYes}
                            message={"Are you sure to delete this user ?"}
                        />
                    )}
                </div>

        </>


    )
    // }
}


export default UserList;