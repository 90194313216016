import { useState, useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import Captcha from "@src/view/common/Captcha";
import { CaptchaData } from "@src/view/common/Captcha";
import { createOtp, googleWithLogin } from "@src/store/slices/user";
import VerifyOtp from "./VerifyOtp";
import secureStorage from "@src/config/encrypt";
import { useDispatch } from 'react-redux';
import GoogleLogin from "./GoogleLogin";
import { toast } from "react-toastify";
import { useSearchParams } from 'react-router-dom';
import { loggedIn } from '../../store/reducers/userReducer1';
import { useApolloClient } from "@apollo/client";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import FullPageLoader from '../../common/FullPageLoader';

interface EmailUserProps {
    setShowMobileLinks: any;
    setShowEmailLinks: any;
    showCaptcha: any;
}

function EmailUser(props: EmailUserProps) {


    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i); // eslint-disable-line

    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false);
    const [inputField, setInputField] = useState<any>({
        email: "",
        password: "",
        captcha: "",
        hash: "",
        mobile: "",
        resendOtp: false
    });
    const [errField, setErrField] = useState<any>({
        email: "",
        password: "",
        isCaptchaInvalid: false,
        captchaError: "",
        captcha: "",
        otp: ""
    });
    const [showVerify, setShowVerify] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>('');
    const navigate = useNavigate();
    const [reloadCaptcha, setReloadCaptcha] = useState(1);
    const [googleLogin, setGoogleLogin] = useState(false);
    const [searchParams] = useSearchParams();
    const history = useNavigate();

    const dispatch = useDispatch();
    const [LoginData, setLoginData] = useState(null);
    const client = useApolloClient();
    const location = useLocation();

    const validForm = () => {
        let formIsValid = true;
        setErrField({
            email: "",
            password: "",
            isCaptchaInvalid: false,
            captchaError: "",
            captcha: "",
            otp: ""
        });

        if (inputField.email === "") {

            formIsValid = false;
            setErrField((prevState:any) => ({
                ...prevState,
                email: "Email is required",
            }));
        } else if (!validEmailRegex.test(inputField.email)) {

            formIsValid = false;
            setErrField((prevState:any) => ({
                ...prevState,
                email: "Please enter valid email",
            }));
        }
        if (inputField.password === "") {

            formIsValid = false;
            setErrField((prevState:any) => ({
                ...prevState,
                password: "Password is required",
            }));
        }
        if (!inputField['captcha'].trim()) {

            formIsValid = false;
            setErrField((prevState:any) => ({
                ...prevState,
                captcha: "Captcha is required",
            }));
            setReloadCaptcha((prev) => prev + 1)
        }

        return formIsValid;
    };

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === "otp") {
            setOtp(value);
        }
        else {
            setInputField({ ...inputField, [name]: value });
        }
        errField[name] = '';
        setErrField({ ...errField });
    };

    useEffect(() => {
        const loggedInUser = secureStorage.getItem("authUserInfo");
        if (loggedInUser && loggedInUser.user_data) {
            navigate('/dashboard');
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        const userDataParam = searchParams.get('userdata'); 
        if (userDataParam) {
          try {
            const parsedData = JSON.parse(decodeURIComponent(userDataParam));  
            if((!parsedData.domain && parsedData.email) || parsedData.domain !== "Invalid"){
              setLoading(true);
              setInputField({ email: parsedData.email, password: "", captcha: ''}); 
    
              googleWithLogin(parsedData, dispatch)
              .then(async (result:any) => { 
                  const loginData = result?.user_data; 
                  if (loginData && !loginData?.is_error) {   
                      //DELETE CAPTCHA HASH FROM CACHE 
   
                    //   localStorage.setItem('loggedIn', '1');
                    //   localStorage.setItem('user_information', JSON.stringify(loginData)); 
                      
                    //   setLoginData(loginData);
                    //   dispatch(loggedIn(loginData));
                      history("/dashboard")
                    //   setUserId(loginData.id);
                    //   setShowVerify(true); 
                    //   setReloadCaptcha((prev)=> prev+1)
                    //   setInputField({ email: inputField.email, password: inputField.password, captcha: '', mobile:''}) 
                  }else{
                    if(result?.data?.message){
                        setLoading(false);
                        // Reset the email and password fields
                        setInputField((prevState:any) => ({
                            ...prevState,
                            email: "",
                            password: "",
                        }));
                        return toast.error(result?.data?.message);
                    
                    }
                    
                  } 
              }) 
            } else {
              setShowVerify(false); 
              toast.error("Domain is Invalid");
              const urlParams = new URLSearchParams(location.search); 
              if (urlParams.has('userdata')) {
                urlParams.delete('userdata');
                const newUrl = `${location.pathname}`;   
                history(newUrl); 
              }
            }
  
          } catch (error) {
            console.error('Failed to parse userdata:', error);
          }
        }
      }, [searchParams]);

       useEffect(() => {  
        //const loggedInUser:any = JSON.parse(localStorage.getItem('user_information'));  
        const userInformation = localStorage.getItem('user_information'); 
        const loggedInUser: any = userInformation ? JSON.parse(userInformation) : null; 
  
        if (loggedInUser && loggedInUser.user_data) {
            history("/dashboard")
        }
    },[])

    const submitButton = async () => {

        setLoading(true);
    
        if (validForm()) {
            if(!inputField['mobile']){
                inputField['mobile'] = ""
            }

            if(!inputField['resendOtp']){
                inputField['resendOtp'] = false
            }
            
            createOtp(inputField, dispatch).then((res:any) => {
               // setLoading(false)
                if (res && res.data && res?.data?.email) {
                    //DELETE CAPTCHA HASH FROM CACHE 
                    setUserId(res && res.data && (res.data?.user_id || res.data?.id));
                    setShowVerify(true);
                    props.setShowEmailLinks(true);
                    props.setShowMobileLinks(false);

                }
                setReloadCaptcha((prev) => prev + 1)
                setInputField({ ...inputField, email: inputField.email, password: inputField.password, captcha: '' })
                setLoading(false);
            }).catch(err=> console.log('errr', err));
            setLoading(false);
        } else {
            setReloadCaptcha((prev) => prev + 1)
            setLoading(false);
        }
    };

    const captchaFiledHandler = (captchaValue: string, captchaImage: CaptchaData) => {
        if (captchaImage.data) {
            setInputField({ ...inputField, captcha: captchaValue, hash: captchaImage.data.value });
        }
    };

    return (
        <div className="login-form">
            <FullPageLoader show={loading} />
            {showVerify ?
            
                (<VerifyOtp submitButton={submitButton} userId={userId} inputField={inputField} otp={otp} setUserId={setUserId} setShowVerify={setShowVerify} setGoogleLogin = {setGoogleLogin} />)
                :
                <form autoComplete="on">
                     <fieldset className="">
                        <div className="material">
                            <GoogleLogin setUserId={setUserId} setShowVerify={setShowVerify} setGoogleLogin={setGoogleLogin} setInputField={setInputField} />
                        </div>
                     </fieldset>

                     <fieldset className="">
                     <div className="t-centre">
                        <label className='m-md-t'>OR</label>
                     </div>
                     </fieldset>
                   
                    
                    <fieldset className="">
                        <div className={"material " + (errField.email ? "error" : "")}    >
                            <input
                                type="text"
                                placeholder=" "
                                name="email"
                                value={inputField.email}
                                className="form-input"
                                onChange={inputHandler}
                            />
                            <i className="ic-email1 icn-login"></i>
                            <label data-label="Email" className="form-label"></label>
                            {errField.email && (
                                <span className="error-txt">
                                    {errField['email'] || ""}
                                </span>
                            )}
                        </div>
                    </fieldset>
                    <fieldset className="">
                        <div className={"material " + (errField.password ? "error" : "")}>
                            <input
                                type="password"
                                placeholder=" "
                                name="password"
                                value={inputField.password}
                                className="form-input password-txt"
                                onChange={inputHandler}
                            //   onKeyPress={handleKeyPress}
                            />
                            <i className="ic-password icn-login"></i>
                            <label data-label="Password" className="form-label"></label>
                            {errField.password && (
                                <span className="error-txt">
                                    {errField['password'] || ""}
                                </span>
                            )}
                        </div>
                    </fieldset>
                    <fieldset className=" captcha-filed">
                        <Captcha onKeyPressCaptcha={captchaFiledHandler} errField={errField} inputField={inputField} reloadCaptcha={reloadCaptcha || props.showCaptcha} />

                    </fieldset>
                    
                    <button type="button" className="btn-primary w-100 m-xl-t" onClick={submitButton} disabled={loading}>
                        Login
                    </button>


                     

                </form>
            }
        </div>
    );
}

export default EmailUser;