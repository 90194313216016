import { default as config } from './config';
import secureStorage from './../config/encrypt';
import { ARO_ROLE_ID, BRO_ROLE_ID, AGENT_ROLE_ID, ARCO_ROLE_ID, DOC_UPLOAD_VALIDATION, VIDEO_UPLOAD_VALIDATION, CURRENCY_SYMBOL } from "../config/constant";
import { toast } from 'react-toastify';

export const DateFormat = (d: any) => {
    return d.getFullYear() + '-' + ((d.getMonth() < 9) ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' + ((d.getDate() < 10) ? '0' + (d.getDate()) : (d.getDate()));
}

export const addCurrencyFormatting = (number: any, countryCode = '') => {
    number = (number !== '' && number !== undefined && number !== null) ? number.toString().replace(/,/g, '') : ''
    return CURRENCY_SYMBOL + ' ' + (+number ? (+number).toLocaleString("en") : 0);
}

export const removeCurrencyFormatting = (number: any) => {
    if (number !== '' && number !== undefined && number !== null) {
        var result = ('' + number).replace(/Rp|AED|₱/g, "").replace(/[^\d.]/g, "").replace(/(\..*)\./g, "$1");        
        return result.toString().trim()
    }

}

/**
 * 
 * @param {*} params 
 * @returns 
 */
export const isUserHasAccessPage = (params: any) => {
    let { permissionID, accessType, assignUserId } = params;
    let isAuthorized = false;
    let authUserInfo: any = secureStorage.getItem('authUserInfo');
    let userDetails = authUserInfo && authUserInfo.user_data;
    if (permissionID) {
        const accessParams = (userDetails && userDetails.feature_access) ? JSON.parse(userDetails.feature_access).filter((feature: any) => +feature.assigned_feature_ids === +permissionID) : [];
        // if(permissionID == 35) console.log("accessParamsaccessParams", accessType,accessParams, permissionID, ''+assignUserId, ''+userDetails.id, highestRoleid);
        if (accessParams && accessParams[0]) {

            // let checkEditAccess = accessParams.filter((feature: any)=> ([1,2].includes(+highestRoleid) && feature.canEdit) || (assignUserId && [3].includes(+feature.assign_role_id) && ''+assignUserId === ''+userDetails.id) || (![1,2,3].includes(+highestRoleid) && feature.canEdit))
            // let checkViewAccess = accessParams.filter((feature: any)=> ([1,2].includes(+highestRoleid) && feature.canView) || (assignUserId && [3].includes(+feature.assign_role_id) && ''+assignUserId === ''+userDetails.id) || (![1,2,3].includes(+highestRoleid) && feature.canEdit))
            // if(permissionID == 17) console.log(checkEditAccess)

            // let checkEditAccess = accessParams.filter((feature: any)=> (([1,2].includes(+highestRoleid) && [1,2].includes(+feature.assign_role_id) && feature.canEdit) || (''+assignUserId && [3].includes(+feature.assign_role_id) && ''+assignUserId === ''+userDetails.id && feature.canEdit) || (![1,2,3].includes(+highestRoleid) && feature.canEdit)))
            // let checkViewAccess = accessParams.filter((feature: any)=> ([1,2].includes(+highestRoleid) && [1,2].includes(+feature.assign_role_id) && feature.canView) || (''+assignUserId && [3].includes(+feature.assign_role_id) && ''+assignUserId === ''+userDetails.id && feature.canView) || (![1,2,3].includes(+highestRoleid) && feature.canView))

            let checkEditAccess = accessParams.filter((feature: any) => ([1].includes(+feature.assigned_role_id) && feature.canEdit) || (assignUserId && [2].includes(+feature.assigned_role_id) && '' + assignUserId === '' + userDetails.id && feature.canEdit) || (![1].includes(+feature.assigned_role_id) && feature.canEdit))
            let checkViewAccess = accessParams.filter((feature: any) => ([1].includes(+feature.assigned_role_id) && feature.canView) || (assignUserId && [2].includes(+feature.assigned_role_id) && '' + assignUserId === '' + userDetails.id && feature.canView) || (![1].includes(+feature.assigned_role_id) && feature.canView))

            // if(permissionID == 35) console.log("accessParamsaccessParams", checkEditAccess);

            // if(accessType === 'edit' && accessParams[0]['canEdit']){
            if (accessType === 'edit' && checkEditAccess.length) {
                isAuthorized = true;

                // if(assignUserId !== undefined && ''+assignUserId !== ''+userDetails.id && ![1,2].includes(+highestRoleid)) {
                //     isAuthorized = false;
                // }
            }
            else if (accessType === 'view' && checkViewAccess.length && userDetails.assigned_role_ids.indexOf("3") >= 0) {
                // else if(accessType === 'view' && accessParams[0]['canView'] && userDetails.assigned_role_ids.indexOf("3")>=0){
                if (+accessParams[0].assigned_feature_ids === 35) isAuthorized = true
                isAuthorized = true;
            }
            else if (accessType === 'isVisible') {
                // else if(accessType === 'isVisible' && (checkEditAccess.length || checkViewAccess.length)){
                isAuthorized = true;
            }
        }
    }

    return isAuthorized;
}

export const numberFormat = (number: any, countryCode = '') => {
    number = (number) ? number.toString().replace(/,/g, '') : ''
    return (+number ? (+number).toLocaleString("en") : 0);
}

/**
 * GET ROLE BY TYPE/ID
 * @param {*} param 
 * @returns 
 */
export const GET_USER_ROLE = (value: any) => {
    let result: any = "";
    // let { key,val } = param;

    if (typeof value === "string") {
        result = (value === "bro") ? BRO_ROLE_ID : (((value === "aro") ? ARO_ROLE_ID : ((value === "agent") ? AGENT_ROLE_ID : ((value === "arco") ? ARCO_ROLE_ID : 0))))
    } else {
        result = (value === 54) ? "bro" : (((value === 57) ? "aro" : ((value === 53) ? "agent" : ((value === 59) ? "arco" : ""))))
    }

    return result;
}

//function to convert file into base 64 for previewing image
export const toBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })
}


/**
 * function to detect media type of base 64 buffer/array
 * @param {Array} b64 
 * @returns {String} match media type
 */
export const detectMimeType = (b64: any, others: any) => {
    return new Promise((resolve, reject) => {
        if (!b64) resolve(false)

        let fileType = b64.split(';')[0]
        b64 = b64.split(',')[1]

        const signatures: any = {
            iVBORw0KGgo: "image/png",
            "/9j/": "image/jpg",
            ffd8ffe0: "image/jpeg",
            ffd8ffe1: "image/jpeg",
            ffd8ffe2: "image/jpeg",
            ffd8ffe3: "image/jpeg",
            ffd8ffe8: "image/jpeg"
        }

        for (const s in signatures) {
            if (b64.indexOf(s) === 0) {
                return resolve(signatures[s])
            }
        }

        //if not image
        if (others && others.allowedExtensions) {
            if (others.allowedExtensions.includes(fileType)) {
                return resolve(true)
            }
        }

        return resolve(false)
    })
}

export const validateFileInput = (files: any, extensions: any = []) => {
    return new Promise(async (resolve, reject) => {
        try {
            let errorFlag = false
            let allowedExtensions = ['data:application/pdf', ...extensions]

            for (let file of files) {
                const base64PreviewImg = await toBase64(file)
                const filetype = await detectMimeType(base64PreviewImg, { allowedExtensions })
                const fileSize = file.size

                if (!filetype) {
                    let msg = `File format should be jpg, jpeg, png, pdf`
                    let isVideoExtension = allowedExtensions.includes('data:video/mp4')
                    msg = msg + (isVideoExtension ? ', mp4, mov' : '')
                    toast.error(msg)
                    errorFlag = true
                    break
                } else if (fileSize < DOC_UPLOAD_VALIDATION['minSize'] || fileSize > DOC_UPLOAD_VALIDATION['maxSize']) {
                    toast.error("file size should be between 25kb to 5mb")
                    errorFlag = true
                    break
                }
            }

            return resolve(errorFlag)
        } catch (error) {
            return resolve(true)
        }
    })
}


export const detectVideoMimeType = (b64: any, others: any) => {
    return new Promise((resolve, reject) => {
        if (!b64) resolve(false)

        b64 = b64.split(',')[1]

        const signatures: any = {
            "AAAAIGZ0eXBxdCAg": 'mov',
            "AAAAG2Z0eXBpc29t": 'mp4',
            "AAAAEGZ0eXBtcDQy": 'mp4',
            "AAAAIGZ0eXBpc29t": 'mp4',
            "AAAAFGZ0eXBxdCAg": 'mov'
        }

        const header = b64.slice(0, 16);

        // Check if the header matches any known signature for MOV or MP4
        return resolve(signatures[header] ? true : false)
    })
}


export const validateVideoInput = (files: any, extensions: any = []) => {
    return new Promise(async (resolve, reject) => {
        try {
            let errorFlag = false

            for (let file of files) {
                const base64PreviewImg = await toBase64(file)
                const filetype = await detectVideoMimeType(base64PreviewImg, {})
                const validMimeTypes = ["video/mp4", "video/quicktime"]

                if (!filetype && !validMimeTypes.includes(file.type)) {
                    let msg = `File format should be mp4 or mov`
                    errorFlag = true
                    toast.error(msg)
                    break
                }
            }

            return resolve(errorFlag)

        } catch (error) {
            return resolve(true)
        }
    })
}


export const nthNumber = (number: number) => {
    if (number > 3 && number < 21) return ("th");
    switch (number % 10) {
        case 1:
            return ("st");
        case 2:
            return ("nd");
        case 3:
            return ("rd");
        default:
            return ("th");
    }
};