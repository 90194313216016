import useClaimProcessingData from '@src/hooks/useClaimProcessingData';
import FullPageLoader from '@src/view/common/FullPageLoader';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as HELPER from '@src/config/helper';
import { getClaimClosingData, updateClaimDetailsInStore, updateClaimHistory, updateClaimRibbonData, fetchClaimProcessingData, saveDocUploadClaimClosingSection } from '@src/store/slices/claim';
import client from '@store/apolloClient';
import { useLocation, useSearchParams, useParams } from 'react-router-dom';
import { DOC_UPLOAD_VALIDATION, STATUS_SUB_STATUS, WHATSAPP, EMAIL, SEND_TO_CUST_DOCS, COMPREHENSIVE, REGEX_DECIMAL_VALIDATION, SEND_TO_CUST_CLAIM_CLOSING, ROLE_FEATURES } from '@src/config/constant';
import { UPLOAD_FILE, UPLOAD_FILE_CLAIM_CLOSING } from '@src/services/claimdata.gql';
import { print } from 'graphql'
import { executeUpload } from '@common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import DocumentView from '@common/DocumentView';
import { getRequestedDocuments, templateFieldInfo, saveCustomerTemplateData, updateTemplateValue } from '@store/slices/template';
import PendingDocumentPopup from './PendingDocumentPopup';
import WhatApp from "@src/webroot/images/whatsapp.svg";
import Viber from "@src/webroot/webroot/images/Viber.svg";
import Mail from '@src/webroot/images/mail.svg'
import { WHATSAPP_WEB_URL } from '@src/config/constant'
import AskToCustomerPopup from '@common/AskToCustomerPopup';


export default function ClaimClosing(props: any) {
    const [askCustomer, setAskCustomer] = useState(false);
    const [documents, setDocuments] = useState<any>([])
    const [errorField, setErrorField] = useState<any>({})
    const [inputField, setInputField] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const [deletedFiles, setDeletedFiles] = useState<any>([])
    const [settlementDone, setSettlementDone] = useState(true)
    const [callClaimClosingApi, setCallClaimClosingApi] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [showConfirmPopup, setShowConfirmPopup] = useState(false)
    const [pendingDocuments, setPendingDocuments] = useState<Array<any>>([])
    const [formData, setFormData] = useState<any>({
        doc_id: [],
        template_type: EMAIL,
        description: ''
    });

    const [claimData, setClaimData] = useState<any>();

    const partnerTemplate = useSelector((state: any) => state.template)

    const location = useLocation()
    const dispatch: any = useDispatch()

    const claimId = location?.pathname?.split('/')?.[2] || null

    const claimState = useSelector((state: any) => state.claim)
    const templateinfo = useSelector((state: any) => state.template)

    useClaimProcessingData({ type: 'docUpload', api_type: 'docUpload', call_from: 'claimClosing' })

    const showAskCustomerPopup = () => {
        setAskCustomer(true);
        document.body.classList.add("overflow-hidden");
    };

    const hideAskCustomerPopup = () => {
        setAskCustomer(false);
        document.body.classList.remove("overflow-hidden");
    };

    const getclaimClosingDetails = async (claim_id: any) => {
        setCallClaimClosingApi(true)
        const result: any = await getClaimClosingData(client, { claim_id })
        if (result?.data?.cash_settlement_done || result?.data?.cash_settlement_done === 0) {
            const updatedData = { ...result?.data, claim_closing_remarks: result?.data?.remarks }
            dispatch(updateClaimDetailsInStore({ ...claimState?.claimDetails, ...updatedData }))
        }
    }

    const handleInputChange = (value: any, fieldItem: any) => {
        if (fieldItem === 'bill_amount') {
            if (!REGEX_DECIMAL_VALIDATION.test(value)) {
                return
            }
        }

        if (fieldItem === 'bank_account_no') {
            if (!(/^\d*$/).test(value)) {
                return
            }
        }

        setInputField((prev: any) => ({ ...prev, [fieldItem]: value }))
    }


    const fetchCustomerPendingDocuments = async () => {
        const result: any = await getRequestedDocuments({ claim_id: claimId, key: 'claimClosing' })
        setPendingDocuments(result?.data || [])
    }

    const handleContinueClick = () => {
        if (pendingDocuments?.length) {
            setShowConfirmPopup(true)
        } else {
            handleSave()
        }
    }

    const validateErr = () => {
        let errorOb: any = {}
        let isError = true;
        let notUploadedDocs = []

        for (let ob of documents) {
            if (ob.is_required && !ob?.images?.find((e: any) => !e.is_resubmit || e.is_resubmit === 0)) {
                isError = false;
                errorOb[ob.value] = `${ob.label} Field is required`;
                notUploadedDocs.push(ob.label)
            }
        }

        setErrorField((prev: any) => ({ ...prev, ...errorOb }));
        return { isError, notUploadedDocs };
    }

    const uploadDeleteDocuments = async (fieldItem: any, file: any, type?: string) => {
        setLoading(true)

        let docDetails: any = { document_id: +fieldItem.value, type: type ?? 'add' }
        //case when customer is deleting
        if (type === 'delete') docDetails['id'] = file.id

        let finalDocuments: any = [{ ...docDetails }]
        let filesArray: any = type === 'delete' ? [] : [file[0]]

        const formData = new FormData();
        const operations = JSON.stringify({
            query: print(UPLOAD_FILE()),
            variables: {
                file: filesArray.map(() => null),
                claim_id: claimId,
                documents: JSON.stringify(finalDocuments),
                api_type: 2 //representing claim closing section
            }
        });

        const map: any = {};
        filesArray.forEach((_: any, index: any) => {
            map[index] = [`variables.file.${index}`];
        });

        formData.append('operations', operations);
        formData.append('map', JSON.stringify(map));

        filesArray.forEach((file: any, index: any) => {
            formData.append(index.toString(), file);
        });

        try {
            const result = await executeUpload(formData)
            let { status, data } = result?.data?.uploadFile

            if (status && status === 200) {
                if (data?.addedDocument?.length) {
                    let docCategory = +fieldItem?.value
                    let updatedDocs = documents?.map((e: any) => {
                        if (e.value !== docCategory) return e

                        let updatedItem = { ...e }
                        let updatedImgs = [...data.addedDocument]
                        if (updatedItem?.images?.length) {
                            updatedImgs = [...updatedItem?.images, ...updatedImgs]
                        }

                        return { ...updatedItem, images: updatedImgs }
                    })

                    setDocuments(updatedDocs)
                    toast.success("File uploaded successfully")

                }

                if (data?.deletedDocument?.length) {
                    let updatedDocuments = documents?.map((e: any) => {
                        let doc = { ...e }
                        if (e.value === +fieldItem.value) {
                            doc['images'] = doc['images']?.filter((el: any) => el.id !== file?.id)
                            return doc
                        } else {
                            return doc
                        }
                    })

                    setDocuments(updatedDocuments)
                    toast.success("File Deleted Successfully")
                }

                // setInputField({})
                setDeletedFiles([])
            } else {
                let error = result?.error || "Error in Uploading file"
                toast.error(error)
            }
        } catch {

        } finally {
            setLoading(false);
        }
    }

    // const handleSave = async () => {
    //     setLoading(true)

    //     const { isError, notUploadedDocs } = validateErr()
    //     if (!isError) {
    //         setLoading(false)
    //         toast.error(`Please upload ${notUploadedDocs.toString()}`)
    //         return false
    //     }

    //     let finalDocuments: any = [...deletedFiles]
    //     let filesArray: any = []
    //     let claimClosingDetails: any = { cash_settlement_done: settlementDone ? 1 : 0 }

    //     for (let document in inputField) {
    //         if (!inputField[document]) continue
    //         //continue if input field is non file fields
    //         if (['string', 'number'].includes(typeof inputField[document])) {
    //             claimClosingDetails[document] = inputField[document]
    //             continue
    //         }
    //         for (let img of inputField[document]) {
    //             let obj = {
    //                 document_id: +document,
    //                 type: 'add'
    //             }
    //             finalDocuments.push(obj)
    //             filesArray.push(img)
    //         }
    //     }

    //     const formData = new FormData();

    //     const operations = JSON.stringify({
    //         query: print(UPLOAD_FILE_CLAIM_CLOSING()),
    //         variables: {
    //             file: filesArray.map(() => null),
    //             claim_id: claimId,
    //             documents: JSON.stringify(finalDocuments),
    //             is_new_created: null,
    //             claim_closing_details: JSON.stringify(claimClosingDetails)
    //         }
    //     });

    //     const map: any = {};
    //     filesArray.forEach((_: any, index: any) => {
    //         map[index] = [`variables.file.${index}`];
    //     });

    //     formData.append('operations', operations);
    //     formData.append('map', JSON.stringify(map));

    //     filesArray.forEach((file: any, index: any) => {
    //         formData.append(index.toString(), file);
    //     });

    //     try {
    //         const result = await executeUpload(formData)
    //         let { status, data } = result?.data?.uploadFile

    //         if (status && status === 200) {
    //             let updatedRibbonData = claimState?.ribbonData?.map((e: any) => {
    //                 if (e.key === 'status') {
    //                     return { ...e, value: data.status_id }
    //                 }
    //                 return e
    //             })

    //             let updatedHistory = claimState?.claimHistory?.includes(data.status_id) ? [] : [...claimState?.claimHistory, data.status_id]
    //             let updatedClaimClosingDetails = { ...claimClosingDetails, claim_closing_remarks: claimClosingDetails.remarks || '' }
    //             delete updatedClaimClosingDetails['remarks']

    //             dispatch(updateClaimDetailsInStore({ ...claimState?.claimDetails, status_id: data.status_id, ...updatedClaimClosingDetails }))
    //             dispatch(updateClaimRibbonData(updatedRibbonData)) //update claim status in ribbon
    //             if (updatedHistory.length) dispatch(updateClaimHistory(updatedHistory)) //update claim status history
    //             setInputField({})
    //             setDeletedFiles([])
    //             setSearchParams(`?${new URLSearchParams({ type: 'transfer-proof' })}`)
    //             toast.success("Data saved successfully")
    //         }
    //     } catch {

    //     } finally {
    //         setLoading(false);
    //         setShowConfirmPopup(false)
    //     }

    // }

    const handleSave = async () => {
        setLoading(true)

        const { isError, notUploadedDocs } = validateErr()
        if (!isError) {
            setLoading(false)
            toast.error(`Please upload ${notUploadedDocs.toString()}`)
            return false
        }

        try {
            let claimClosingDetails: any = { cash_settlement_done: settlementDone ? 1 : 0 }

            for (let document in inputField) {
                if (!inputField[document]) continue
                //continue if input field is non file fields
                if (['string', 'number'].includes(typeof inputField[document])) {
                    claimClosingDetails[document] = inputField[document]
                    continue
                }
            }

            let options = {
                claim_id: id,
                type: 2, //claim closing
                claim_closing_details: claimClosingDetails
            }

            const result: any = await saveDocUploadClaimClosingSection(client, options)
            if (result && result?.status === 200) {
                const { data } = result
                let updatedRibbonData = claimState?.ribbonData?.map((e: any) => {
                    if (e.key === 'status') {
                        return { ...e, value: data.status_id }
                    }
                    return e
                })

                let updatedHistory = claimState?.claimHistory?.includes(data.status_id) ? [] : [...claimState?.claimHistory, data.status_id]

                dispatch(updateClaimDetailsInStore({ ...claimState?.claimDetails, status_id: data.status_id }))
                dispatch(updateClaimRibbonData(updatedRibbonData)) //update claim status in ribbon
                if (updatedHistory.length) dispatch(updateClaimHistory(updatedHistory)) //update claim status history
                setDeletedFiles([])
                setSearchParams(`?${new URLSearchParams({ type: 'transfer-proof' })}`)
                toast.success("Data saved successfully")
            } else {
                toast.error("Error Occured")
            }
        } catch (error) {

        } finally {
            setLoading(false)
            setShowConfirmPopup(false)
        }
    }

    useEffect(() => {
        let { documents } = claimState?.claimDetails || {}
        if (documents?.length) {
            let updatedDocs = []
            if (settlementDone) {
                updatedDocs = documents?.filter((e: any) => e.slug !== 'bill_document')
            } else {
                updatedDocs = documents?.filter((e: any) => e.slug === 'bill_document')
            }
            setDocuments(updatedDocs)

            if (!callClaimClosingApi) {
                getclaimClosingDetails(claimId)
            }
        }

    }, [claimState?.claimDetails?.documents, settlementDone])

    useEffect(() => {
        fetchCustomerPendingDocuments()
        if (claimState?.claimDetails?.cash_settlement_done || claimState?.claimDetails?.cash_settlement_done === 0) {
            let { cash_settlement_done, bank_account_no, name_on_bank, claim_closing_remarks, bill_amount } = claimState?.claimDetails
            let isCashSettlementDone = cash_settlement_done === 1 ? true : false
            setSettlementDone(isCashSettlementDone)
            setInputField((prev: any) => ({ ...prev, cash_settlement_done, bank_account_no, name_on_bank, remarks: claim_closing_remarks, bill_amount }))
        }
    }, [claimState?.claimDetails?.cash_settlement_done])

    useEffect(() => {
        //fetch pending documents
        fetchCustomerPendingDocuments()
    }, [])


    // fetch template description
    useEffect(() => {
        const fetchTemplateData = async () => {
            if (partnerTemplate?.partnerConfigTemplate?.cohort_list?.length) {
                const templateData = partnerTemplate.partnerConfigTemplate.cohort_list.filter((val: any) => val.template_type === EMAIL);
                setFormData((prev: any) => ({ ...prev, description: templateData[0]?.description || '', doc_id: templateData[0]?.document_id || [] }));
            } else {
                let params: any = {
                    partner_id: claimData?.partner_hash_id,
                    template_for: SEND_TO_CUST_CLAIM_CLOSING,
                    insurance_type: claimData?.insurance_type,
                    key: "ClaimClosing",
                    claim_id: id,
                    lead_id: claimData?.insurance_id || 0
                };

                dispatch(templateFieldInfo(params)).then((res: any) => {
                    const templateData = res?.data?.cohort_list?.filter((val: any) => val.template_type === EMAIL);
                    setFormData((prev: any) => ({ ...prev, description: templateData?.[0]?.description || '', doc_id: templateData?.[0]?.document_id || [] }));
                });
            }
        };

        if (claimData?.partner_hash_id) {
            fetchTemplateData();
        }

    }, [dispatch, claimData]);

    // claim id
    const { id } = useParams();

    // if claimDetails is not present in store then call api for fetch calim details
    useEffect(() => {
        if (claimState?.claimDetails?.partner_hash_id) {
            setClaimData(claimState?.claimDetails);
            //set data
        }
        //  else {
        //     fetchClaimProcessingData({variables: { claim_id: id, api_type: "applicationDetails" } }).then((res: any) => {
        //         if (res?.data) {
        //             setClaimData(res?.data)
        //             // setDocuments(res?.data?.documents);
        //         }

        //     })
        // }
    }, [claimState?.claimDetails])


    // Function to handle template_type setting
    const handleTemplateTypeChange = (type: number) => {
        const selectedTemplate = templateinfo?.partnerConfigTemplate?.cohort_list?.find((template: any) => template?.template_type === type);
        if (selectedTemplate) {
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                template_type: selectedTemplate.template_type,
                description: selectedTemplate.description
            }));
        }
    };


    const shareUrlCustomer = () => {
        // if (!checkValidateData()) {
        //     return;
        // }

        setLoading(true)
        let template_input: any = {};
        template_input.description = formData?.description || ''
        template_input.claim_id = id || ''
        template_input.template_type = formData?.template_type || []
        template_input.doc_id = formData?.doc_id || []
        template_input.key = 'claimClosing'

        saveCustomerTemplateData({ template_input }).then((res: any) => {
            if (res?.status === 200) {
                toast.success(res.message)
                hideAskCustomerPopup()
                setLoading(false)
                // setTrigger(trigger + 1);


                const templateData = partnerTemplate?.partnerConfigTemplate?.cohort_list?.map((val: any) => {
                    let value = { ...val }
                    if (val.template_type === EMAIL) {
                        value.document_id = formData?.doc_id
                        return value;
                    }
                    return value
                });
                dispatch(updateTemplateValue(templateData))
            }
            // share detail on whatsapp   
            if (formData?.template_type === WHATSAPP) {
                let waMsg = "";
                let openWhatsapp = true;
                let formatMsg = formData?.description.replaceAll('<br/>', "\n");
                waMsg = WHATSAPP_WEB_URL + encodeURIComponent(formatMsg);
                if (openWhatsapp) window.open(waMsg, '_blank', 'noreferrer');
                setLoading(false)
                // resetData();
            }
        })

    }

    const handleCheckboxChange = (event: any, docId: any) => {
        const checked = event.target.checked;
        setFormData((prevData: any) => {
            // If checked, add docId to doc_id array, otherwise remove it
            const currentDocIds = Array.isArray(prevData.doc_id) ? prevData.doc_id : [];

            const updatedDocIds = checked
                ? [...currentDocIds, docId]  // Use currentDocIds here
                : currentDocIds.filter((id: any) => id !== docId);

            return { ...prevData, doc_id: updatedDocIds };
        });
    };


    return (
        <>
            <FullPageLoader show={loading} />

            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className="lead-form-heading  lead-top-heading">
                        <h2>Claim Closing</h2>
                        {
                            !HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['update_claim_application'], accessType: 'edit', assignUserId: claimState?.claimDetails?.assign_user_id }) || +claimState?.ribbonData?.find((e: any) => e.key === 'status')?.value !== STATUS_SUB_STATUS['status']['loa-received']
                                ?
                                <button className='btn-line ask-coustmer-btn' disabled>
                                    <i className='ic-upload'></i>
                                    Ask Customer to Upload Docs
                                </button>
                                :
                                <button className='btn-line ask-coustmer-btn' onClick={showAskCustomerPopup}>
                                    <i className='ic-upload'></i>
                                    Ask Customer to Upload Docs
                                </button>
                        }
                    </div>
                    <div className="lead-form-filed p-lg-t">

                        <div className='yes-no-option'>
                            <h3>Cash Settlement Done</h3>
                            <ul>
                                <li className={settlementDone ? `active` : ''} onClick={() => setSettlementDone(true)}>
                                    <i className='ic-check'></i>
                                    yes
                                </li>
                                <li className={settlementDone ? '' : 'active border-color-red'} onClick={() => setSettlementDone(false)}>
                                    <i className='ic-clearclose'></i>
                                    No
                                </li>
                            </ul>
                        </div>
                        <h3>Claim Closing Details and Documents</h3>
                        <div className='row p-md-t'>
                            {
                                settlementDone
                                    ?
                                    <>
                                        <fieldset className="form-filed col-md-4">
                                            <div className="material">
                                                <input id="" type="text" placeholder=" " name="bank_account_no" className="form-input" onChange={(e: any) => handleInputChange(e.target.value, 'bank_account_no')} value={inputField?.['bank_account_no'] || ''} />
                                                <label data-label="Customer Bank Account No." className="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset className="form-filed col-md-4">
                                            <div className="material">
                                                <input id="" type="text" placeholder=" " name="name_on_bank" className="form-input" onChange={(e: any) => handleInputChange(e.target.value, 'name_on_bank')} value={inputField?.['name_on_bank'] || ''} />
                                                <label data-label="Name On Bank" className="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </>
                                    :
                                    <fieldset className="form-filed col-md-4">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="bill_amount" className="form-input" onChange={(e: any) => handleInputChange(e.target.value, 'bill_amount')} value={inputField?.['bill_amount'] || ''} />
                                            <label data-label="Bill Amount" className="form-label"></label>
                                        </div>
                                    </fieldset>
                            }
                        </div>

                        <DocumentView documents={documents} setDocuments={setDocuments} handleSave={handleContinueClick} setDeletedFiles={setDeletedFiles}
                            inputField={inputField} setInputField={setInputField} errorField={errorField} setErrorField={setErrorField} setLoader={setLoading}
                            settlementDone={settlementDone} handleInputChange={handleInputChange} callFrom={"claim-closing"}
                            uploadDeleteFile={uploadDeleteDocuments} />

                        {
                            showConfirmPopup
                                ?
                                <PendingDocumentPopup show={showConfirmPopup} list={pendingDocuments} close={() => setShowConfirmPopup(false)} submit={handleSave} />
                                : undefined
                        }
                    </div>
                </div>

                {
                    askCustomer &&

                    <AskToCustomerPopup
                        formData={formData}
                        documents={documents}
                        setFormData={setFormData}
                        setLoading={setLoading}
                        askCustomer={askCustomer}
                        setAskCustomer={setAskCustomer}
                        keys="claimClosing"
                        template_for={SEND_TO_CUST_CLAIM_CLOSING}
                        claimData={claimData}
                    />
                }


            </div>

        </>
    )
}


