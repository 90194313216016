import React, { Children, Component, useEffect, useState } from 'react';
import RoleListEdit from './RoleListEdit';
import Pagination from '../../common/Pagination';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
// import ConfirmPopUp from '../../common/ConfirmPopUp';
// import { getRoleListing } from '../../store/slices/RoleUser';
import FullPageLoader from "../../common/FullPageLoader";
import AddRolePopup from './AddRolePopup';
// import * as HELPER from '../../config/helper';
import { ROLE_FEATURES } from '@src/config/constant';
import { getRoleListing, getFeaturesData, updateStatus } from '../../../store/slices/RoleUserSlice';
import { getUserListing } from '../../../store/slices/UserSlice';
import ConfirmPopup from '@src/view/common/ConfirmPopUp';
import * as HELPER from "@src/config/helper";


const RoleListTable = (props: any) => {
    const dispatch: any = useDispatch();
    let [roleMappingList, setRoleMappingList] = useState<any>([]);
    let [featureList, setFeatureList] = useState<[]>([]);
    let [userList, setUserList] = useState<[]>([]);
    const [paginationData, setPaginationData] = useState<any>({});
    const [showPopup, ShowPopup] = useState(false);
    const [editRoleData, setEditRoleData] = useState({});
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showAddRole, setShowAddRole] = useState(false);
    const [searchParams, setSearchParams] = useState<any>({ page_number: 1 });

    const roleUserState = useSelector((state: any) => state.roleUser);

    const showEditPopUp = (action: boolean, data: any) => {
        ShowPopup(action);
        action ? setEditRoleData(data) : setEditRoleData({});            // set data for edit role popup.
        document.body.classList.remove("overflow-hidden");

        if (action) {
            document.body.classList.add("overflow-hidden");
        }
    }

    // useEffect(()=>{

    //     if(roleUserState.data?.length){
    //         setPaginationData({ ...roleUserState?.pagination });
    //         setRoleMappingList([...roleUserState?.data]);
    //     }

    // }, [roleUserState])

    const getRoleListDetails = async (params: any) => {
        // let filters = {
        //     page_number: params?.page_number ?? 1
        // }
        setLoading(true);
        // dispatch(getRoleListing.getRoleData(filters)).then((res: any) => {
        dispatch(getRoleListing({ 'filter_list': { ...searchParams, ...params } }))
            .then((res: any) => {
                if (res?.status === 200) {

                    setPaginationData({ ...res?.pagination });
                    setRoleMappingList([...res?.data]);
                }
                setLoading(false);
            }).catch((err: Error) => {
                setLoading(false);

            })
    };

    const getFeatureListDetails = async (params?: { page_number: number }) => {
        let filters = {
            page_number: params?.page_number ?? 1
        }
        setLoading(true);
        getFeaturesData({ filter_list: filters }).then((res: any) => {
            if (res && res.status === 200) {
                setFeatureList(res.data);
            }
            setLoading(false);
        }).catch((err: Error) => {
            setLoading(false);
        })
    };

    const getUserListDetails = async (params?: { page_number: number }) => {
        let filters = {
            page_number: params?.page_number ?? 1
        }
        setLoading(true);
        dispatch(getUserListing({ filter_list: {} })).then((res: any) => {
            if (res && res.status === 200) {
                setUserList(res.data);

            }
            setLoading(false);
        }).catch((err: Error) => {
            setLoading(false);
        })
    };


    const showDeletePopUp = async (action: boolean, id: any) => {
        setIdToDelete(id);
        setShowConfirmation(true);
    }

    const confirmYes = async () => {
        updateStatus({ requestData: { id: idToDelete, is_delete: 1} }).then((res: any) => {
            if (res && res.status === 200) {
                toast.success(res.message)
                getRoleListDetails({});
            }
        });
        confirmNo();
    };

    const confirmNo = () => {
        setShowConfirmation(false);
    };


    const runPaginationFunc = () => { };
    const handleRequestData = (params: any) => {
        let apiParams = {
            page_number: params.page_no
        }
        getRoleListDetails(apiParams);
    }

    const addNewRole = (action: boolean) => {
        setShowAddRole(action);
        document.body.classList.remove("overflow-hidden");

        if (action) {
            document.body.classList.add("overflow-hidden");
        }
    }

    const getApiData = async () => {
        await getRoleListDetails({});
        await getFeatureListDetails();
        await getUserListDetails();
    }

    useEffect(() => {
        getApiData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <FullPageLoader show={loading} />
            {
                HELPER.isUserHasAccessPage({permissionID: ROLE_FEATURES['user_role_management'], accessType: 'edit'}) &&
                <div className="add-role-btn">
                    <button className="btn-line" onClick={() => addNewRole(true)}>
                        <span>Add Role</span>
                    </button>
                </div>
            }


            <div className="lead-list-data-table role-managment-table-data data-table">
                <table>
                    <thead>
                        <tr>
                            <th>Role</th>
                            <th>Description</th>
                            <th>User Assigned</th>
                            <th>Feature Access</th>
                            <th>Status</th>
                            <th>Edit</th>
                            {
                                HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['user_role_management'], accessType: 'edit' }) &&
                                <th>Delete</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {(roleMappingList && roleMappingList.length > 0) ? roleMappingList.map((row: any, index: number) => {
                            // remove 'My Cases = 35' and 'Assign Lead to User = 34'
                            const filteredAssignFeatures = row?.assign_features?.filter((feature: any) => feature.feature_id !== 34 && feature.feature_id !== 35);
                            return (
                                <tr key={index}>
                                    <td>{row.roleName}</td>
                                    <td title={row.description}>{row.description}</td>
                                    <td>{row.user_ids ? row.user_ids.split(",").length : 0} Users</td>
                                    <td>{filteredAssignFeatures?.length} Features</td>
                                    <td><span className={+row.status ? "active-dot" : "inactive-dot"}>{+row.status ? "Active" : "InActive"}</span></td>
                                    <td>
                                        <ul className="action-btn">
                                            <li onClick={() => showEditPopUp(true, row)}>
                                                <i className="ic-edit"></i>
                                            </li>
                                        </ul>

                                    </td>

                                    {
                                         HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['user_role_management'], accessType: 'edit' }) &&
                                        <td className="action-btn">
                                            <ul className="action-btn">
                                                <li onClick={() => showDeletePopUp(true, row.id)}>
                                                    <i className="ic-delete"></i>
                                                </li>
                                            </ul>
                                        </td>
                                    }
                                </tr>)

                        })
                            :
                            <tr><td colSpan={7} style={{ textAlign: 'center' }}>No data found</td></tr>
                        }
                    </tbody>
                </table>
                {(paginationData && paginationData['total'] > 10) && <Pagination paginateData={{
                    page: 1,
                    runPaginationFunc,
                    loading: false,
                    tableData: paginationData['limit'],
                    totalLengthWithoutPagination: paginationData['total'],
                    paginationData,
                    filteredData: {}
                }}
                    sendRequestDataToParent={handleRequestData} />}

                {showConfirmation === false ? ("") : (
                    <ConfirmPopup
                        confirmNo={confirmNo}
                        confirmYes={confirmYes}
                        message={'Are You sure to delete this Role?'} />
                )}
                {showPopup && <RoleListEdit
                    setLoading={setLoading}
                    showPopup={showPopup}
                    editRoleData={editRoleData}
                    featureList={featureList}
                    roleUserList={userList}
                    getRoleListDetails={(e: any) => getRoleListDetails(e)}
                    showEditPopUp={(e: boolean, data: any) => {
                        showEditPopUp(e, data);
                    }} />}

                {
                    showAddRole &&
                    <AddRolePopup
                        showAddRole={showAddRole}
                        getRoleListDetails={(e: any) => getRoleListDetails(e)}
                        addNewRole={(e: boolean) => {
                            addNewRole(e);
                        }}
                    />
                }

            </div>

        </>

    )

}

export default RoleListTable

