import React, { useEffect, useState } from "react";
import Modal from "../../common/ModalPopup";
import { toast } from "react-toastify";
import FullPageLoader from "@common/FullPageLoader";
import {getRegionListing,getMasterData } from '@src/store/action/allAction';
import {ADD_REPAIR_SHOP} from '../../../services/repairShop.gql';
import { useSelector, useDispatch } from "react-redux";
import { getMasterConfig } from "@store/slices/common";
import { useApolloClient } from '@apollo/client';
import MultiSelect from '../../../common/MultiSelect';
import ReactSelect from "../../../common/ReactSelect";
import {executeGraphQLMutation} from "../../../common/executeGraphQLQuery";


function AddRepairShopPartner(props: any) {

    let { addNewPartner, showAdd,heading,showPartner,Submitbutton } = props;
    const [errors, setError] = useState<any>({});
    const [formData, setFormData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [regionList, setRegionList] = useState<[]>([]);
    const dispatch: any = useDispatch()
    const commonState: any = useSelector((state: any) => state.common)
    const client = useApolloClient();

    useEffect(() => {
        getApiData();
    }, []);
    
   
    const getApiData = async () => {
        const regionData = await getRegionList();
        await fetchMasterData()
        setRegionList(regionData); 
        if(props && props?.partnerId){
            let updatedData = JSON.parse(JSON.stringify(formData));
            updatedData["partner_id"]=[props?.partnerId];
            setFormData({...updatedData});
        }
    }

    const getRegionList = async () => {
        let result: any = null
        if (commonState?.regionList?.length) {
            result = { data: commonState.regionList }
        } else {
            result = await dispatch(getRegionListing(client))
        }
        if (result?.data?.length) {
            return result.data
        }
        return []
    }

    const fetchMasterData = async () => {
        let data: any = {}
        if (commonState?.common && Object.keys(commonState.common || {})?.length) {
            data = commonState.common
        } else {
            data = await dispatch(getMasterConfig())
        }

        return data
    }

   

    const handleChange = async (event:any, key:string) => {

        let updatedData = JSON.parse(JSON.stringify(formData));
		if (key==='partner_id') {
            let selectedOptionsArray = event.map((v: any) => v.value);
            updatedData = { ...updatedData, [key]: selectedOptionsArray };
        }else if (key==='name') {
            updatedData = { ...updatedData, [key]: event.target.value };
        }else if (key==='region_id') {
            updatedData = { ...updatedData, [key]: event.value };
        }
        errors[key] = '';
		setError({ ...errors });
	    setFormData({...updatedData});

	}

	const isFormValid = (formData:any) => {
		let isValid = true;
		if (!formData?.name || formData.name === '') {
			errors['name'] = 'This Field is Required';
			isValid = false;
		}
        if (!formData.region_id || formData.region_id === '') {
			errors['region_id'] = 'This Field is Required';
			isValid = false;
		}
        if (!formData.partner_id || formData.partner_id === '' || !formData.partner_id.length) {
			errors['partner_id'] = 'This Field is Required';
			isValid = false;
		}
		
		setError({ ...errors });
		return isValid;

	}

	const handleAddPartner = async (e:any) => {
		e.preventDefault();
		if (isFormValid(formData)) {
            setLoading(true);
            let formFields={
                saveRepairInput:{
                    ...formData
                }
            }
            executeGraphQLMutation(ADD_REPAIR_SHOP,formFields, client).then((response:any) => { 
                setLoading(false);
                let { status,message } = response?.data?.addRepairShop;
                if(status && status===200){
                    toast.success(message);
                    setFormData({});
                    addNewPartner(false);
                    props.reloadShopData();  
                }else{
                    toast.error(message);
                }
            }).catch((error:any) => {
                setLoading(false);
                toast.error(error);
            });
		}
	}
    return (
        <React.Fragment>
            <div className="user-screen-popup add-role-popup">
                <FullPageLoader show={loading} />

                <Modal handleClose={() => addNewPartner(false)} show={showAdd}>
                    <div className="modal-header">
                        <h2>{heading}</h2>
                    </div>
                    <div className="modal-body" >
                        <div className="row">
                            {
                               (showPartner)?
                                <div className="col-md-12">
                                    <fieldset className="single-select">
                                        <div className="material">
                                            <MultiSelect
                                                key={"dropdown"}
                                                options={commonState?.common?.partner_list || []}
                                                value={commonState?.common?.partner_list?.filter((v: any) => formData.partner_id?.includes(v.value))}
                                                onChange={(e: any) => handleChange(e, 'partner_id')}
                                                isSelectAll={true}
                                                placeholder={"Insurance Partner *"}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                            />
                                                {errors && errors['partner_id'] ? <span className="error-txt">{errors.partner_id}</span> : null}
                                        </div>
                                    </fieldset>
                                </div>:null
                            }
                           
                            <div className="col-md-12 p-lg-t">
                                <fieldset className="form-filed">
                                    <div className="material">
                                        <input id="name" 
                                        type="text" 
                                        placeholder=" " 
                                        className="form-input" 
                                        value={formData.name} 
                                        onChange={(e) => handleChange(e, "name")}/>
                                        <label data-label="Repair Shop Name *" className="form-label"></label>
                                        {errors && errors['name'] ? <span className="error-txt">{errors.name}</span> : null}
                                        
                                    </div>
                                </fieldset>
                            </div>
                            <div className="col-md-12">
                                <ReactSelect
                                options={regionList}
                                value={formData && formData["region_id"] && regionList?.filter((v: any) => +v.value === +formData?.region_id)}
                                placeholder="Repair Shop Region *"
                                fieldKey=""
                                fieldItemName="region_id"
                                isDisabled={false}
                                errorField={errors?.region_id}
                                handleChange={(e:any) => handleChange(e,"region_id")}
                                />
                            </div>
                            
                            <div className="col-md-12">
                                <button className="btn-primary" onClick={(e) => handleAddPartner(e)}>{Submitbutton}</button>
                                <button className="btn-line m-md-l" onClick={(e) =>  addNewPartner(false)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default AddRepairShopPartner