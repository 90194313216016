import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
//import { getTemplateFields, getTemplateFieldinfo, updateTemplate } from '@src/store/slices/insurance';
import { useNavigate } from 'react-router-dom';
import FullPageLoader from '../../common/FullPageLoader';
import ReactSelect from '../../common/ReactSelect';
import * as HELPER from "../../config/helper";
import { ROLE_FEATURES, APP_TYPE_STANDALONE, APP_TYPE_WITH_UCF, APP_TYPE_RENEWAL, APP_TYPE_AGENCY, SEND_TO_CUST_DOCS, SEND_TO_CUST_CLAIM_CLOSING, SEND_TO_INS_PARTNER} from "../../config/constant";
import { useDispatch, useSelector } from 'react-redux';
import { getMasterConfig } from '@src/store/slices/common';
import { GET_TEMPLATE_FIELDS, UPDATE_TEMPLATE_DATA,TEMPLATE_FIELD_INFO} from "../../services/templatedata.gql";
import { useApolloClient } from '@apollo/client';
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
// import { getMasterConfig } from "../../store/action/allAction";

let initialFilterData = {
    template_type: "",
    template_for: "",
    payment_status:"",
    insurance_type: ""
};
let resetWarningMsg = "WARNING! The unsaved template changes will be removed.";

const SendToFinancierTemplateDetail = (props) => {
    const client = useApolloClient();
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();


    let [loading, setLoading] = useState(false);
    let [templateDetails, setTemplateDetails] = useState({
        financier_name: "",
        cohort_list: [],
        attributes: {},
        templateType: [],
        templateFor: [],
        payment_status: []
    })
   

    const [groupedData, setGroupedData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState(initialFilterData);
    const [showForm, setShowForm] = useState(false);
    const [templateKey, setTemplateKey] = useState('');
    const [selectionPos, setSelectionPos] = useState(-1);
    const [selectedTemplateData, setSelectedTemplateData] = useState({});
    const [originalGroupedData, setOriginalGroupedData] = useState([]);
    const [showFiled, setShowFiled] = useState(false);
    // eslint-disable-next-line
    const [showTemplateConfiguration, setShowTemplateConfiguration] = useState(true);
    const [originalTemplateType, setOriginalTemplateType] = useState([]);
    const [showEmailField, setShowEmailField] = useState(true);
    const [trigger, SetTrigger] = useState(0);

    const [showPermission, setShowPermission] = useState(true);

    const [errField, setErrField] = useState({
        email: "",
        description: "",
    });

    const [originalDealerSelect, setOriginalDealerSelect] = useState([]);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const commonState = useSelector((state) => state.common)
    
    // Handle changes in dropdown selections
    const handleChange = (selectedOption, key) => {
        let updatedFilters = selectedFilters;
        updatedFilters = { ...updatedFilters, [key]: selectedOption['value'] };
        setSelectedFilters(updatedFilters);
        // set permission
        // if ((updatedFilters?.template_for === 1)) {
        //     setShowPermission(HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['email_template_management'], accessType: 'edit' }));
        // }
        // else if ((updatedFilters?.template_for === 2)) {
        //     setShowPermission(HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['email_template_management'], accessType: 'edit' }))
        // }
        // // set permission true for customer to calim closing
        // else if((updatedFilters?.template_for === 3)){
        //     setShowPermission(true);
        // }

    }

    // Fetch data when the component mounts
    useEffect(() => {
        setLoading(true);
        // Fetch template details data
        
        executeGraphQLQuery(TEMPLATE_FIELD_INFO(), client, {
            partner_id: searchParams.get('partner_id')
        })
        .then((result) => {
            console.log("resultresult", result)

            if(result?.data?.templateFieldsinfo?.status === 200){
            setTemplateDetails(result.data.templateFieldsinfo.data)
            setOriginalTemplateType(result.data.templateFieldsinfo.data.templateType);
            setOriginalDealerSelect(result.data.templateFieldsinfo.data.dealerPresent)
            setLoading(false);
            }else{        
                setLoading(false);  
                navigate('/email-template-config');
            }
        
        }
    );
        // eslint-disable-next-line
    }, [trigger]);


    useEffect(() => {
        setLoading(true);
    
        // Fetch template fields data using executeGraphQLQuery
        const fetchTemplateFields = async () => {
            // Call the GraphQL query with the `executeGraphQLQuery` function
            const result = await executeGraphQLQuery(GET_TEMPLATE_FIELDS(), client);
            if (+result?.data?.templateFieldsDetails?.status === 200) {
            
                // Extract and sort child objects
                const sortedChildObjects = Object.values(result.data.templateFieldsDetails.data)?.map((obj) => {
                    const sortedChild = Object.values(obj.child)?.sort((a, b) => a.order - b.order);
                    return { ...obj, child: sortedChild };
                });
                
                setGroupedData(sortedChildObjects);
                setOriginalGroupedData(sortedChildObjects);
            }
            
            setLoading(false);
        };
    
        fetchTemplateFields(); // Invoke the fetch function
    }, []);


    const addTemplate = () => {
        setShowFiled(false);
        setShowForm(true);
        setSelectedFilters(initialFilterData);
        setSelectedTemplateData({ status: '1', group_name: ``, template_type: 0, template_for: 0, email: '' });

    }


    const handleTextChange = (e) => {
        setSelectedTemplateData({ ...selectedTemplateData, 'description': e.target.value });
    };


    const handleKeySelect = (e, params) => {
        e.stopPropagation();

        if (!params.child || (!params?.child?.length)) {
            setTemplateKey(params.field_key);
        }
    };


    const handleForwardButtonClick = () => {
        if (selectionPos >= 0 && templateKey) {

            const selected = selectedTemplateData?.description || '';
            const newDescription = [
                selected.slice(0, selectionPos),
                `{{${templateKey}}}`,
                selected.slice(selectionPos)
            ].join('');
            setSelectedTemplateData({ ...selectedTemplateData, description: newDescription });
            setTemplateKey('');
        }
    };

    const handleReverseButtonClick = () => {
        if (templateKey) {
            //Remove all key at on click
            const keyRegex = new RegExp(`{{${templateKey}}}`, 'g');
            const updatedText = selectedTemplateData.description.replace(keyRegex, '');
            setSelectedTemplateData({ ...selectedTemplateData, 'description': updatedText });

            setTemplateKey('');
        }
    };


    const SaveTemplateField = async () => {
        setLoading(true);
        if (await validForm()) {
           
            let saveParams = {
                email: selectedTemplateData.email,
                description: selectedTemplateData.description,
                status: +selectedTemplateData.status
            };

            if (selectedTemplateData.template_id) {
                saveParams['templateID'] = +selectedTemplateData?.template_id;
                saveParams['template_for'] = selectedTemplateData.template_for;
                saveParams['template_type'] = selectedTemplateData.template_type;
                saveParams['partner_id'] = searchParams.get('partner_id');
                saveParams['email'] = selectedTemplateData?.email
            } else {
                saveParams['template_type'] = selectedTemplateData.template_type;
                saveParams['template_for'] = selectedTemplateData.template_for;
                saveParams['partner_id'] = searchParams.get('partner_id');
                saveParams['email'] = selectedTemplateData?.email

                // dealer send to customer doc
                if([1,3].includes(selectedTemplateData.template_for)){
                     saveParams['payment_status'] = selectedTemplateData?.payment_status
                }

            }
            saveParams.insurance_type = selectedTemplateData.insurance_type
            
            const result = await executeGraphQLQuery(UPDATE_TEMPLATE_DATA(), client, {
                template_input: saveParams
            });
           
            if(result?.data?.updateTemplate?.status === 200){
                setSelectedTemplateData({ ...selectedTemplateData, template_id: result.data.updateTemplate.data.id });
                toast.success(result.data.updateTemplate.message);
                setLoading(false);
                SetTrigger(trigger + 1);
                if(result.data.updateTemplate.data.id){
                    setShowFiled(true);
                }
            }
            else{
                if(result.data.updateTemplate.message) toast.error(result.data.updateTemplate.message);
                setLoading(false);
            }
        }
        else {
            setLoading(false);
        }
    }

    const submitFilter = () => {

        setLoading(true);
        if (selectedFilters.template_type && selectedFilters.template_for && selectedFilters.insurance_type) {
           // let filteredTemplate = templateDetails?.cohort_list?.find((elm) => (+elm.template_for === +selectedFilters.template_for && +elm.template_type === +selectedFilters.template_type && +elm.insurance_type === +selectedFilters.insurance_type && (([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(+elm.insurance_type) && +elm.payment_status === +selectedFilters.payment_status) || ([APP_TYPE_WITH_UCF, APP_TYPE_AGENCY].includes(+elm.insurance_type) && !elm.payment_status))));
           let filteredTemplate = templateDetails?.cohort_list?.find((elm) => (+elm.template_for === +selectedFilters.template_for && +elm.template_type === +selectedFilters.template_type && +elm.insurance_type === +selectedFilters.insurance_type && (selectedFilters?.payment_status ? +elm.payment_status === +selectedFilters.payment_status : true) ) )
            setSelectedTemplateData({});
            setShowForm(false)
            setShowFiled(false);
            setShowTemplateConfiguration(false);

            if (filteredTemplate && filteredTemplate.partner_id) {

                setSelectedTemplateData(filteredTemplate);

                setShowForm(true)
                setShowFiled(true);
                setShowTemplateConfiguration(true);
                // if template for is whatsapp then email field would reset during reset
                if (selectedFilters?.template_type === 2) {
                    setShowEmailField(true)
                } else {
                    setShowEmailField(false);
                }
            }
            else {
                setLoading(false);
                toast.error('No Financier Template Found');

            }
        }

        leftSideFieldsHandler(selectedFilters);

        setLoading(false);

    }

    const leftSideFieldsHandler = (params) => {
        // filter Details according to template For
        if (Object.keys(originalGroupedData)?.length > 0) {
            let filteredData = [...Object.values(originalGroupedData)];

            if (params.template_for === 2 ) {
                filteredData = filteredData.filter((item) =>
                    +item.insurance_policy_shared === 1
                );

                setGroupedData({ ...filteredData });
            }
            else if (params.template_for === 1) {
                filteredData = filteredData.filter((item) =>
                    +item.send_to_insurance_broker === 1
                );
                setGroupedData({ ...filteredData });
            }
            else if(params.template_for === 3){
                filteredData = filteredData.filter((item) =>
                    +item.send_to_customer_claim_closing === 1
                );
                setGroupedData({ ...filteredData });
            }
        }

    }

    const handleTemplateData = (e, key) => {

        let value = "";
        if (key === "status") {
            value = +selectedTemplateData.status ? '0' : '1'
        }

        setSelectedTemplateData({ ...selectedTemplateData, [key]: value })
    }

    //sub filter handle
    const addTemplateFieldHandler = (e, key) => {
        let updatedSelectedData = { ...selectedTemplateData, [key]: (key === "email" ? e.target.value : e.value) };

        setSelectedTemplateData(updatedSelectedData);
        if (key === 'template_for') {
            setSelectedTemplateData({ ...updatedSelectedData, template_type: '' });
            // when user change Select Template then reset description
            if (updatedSelectedData.description) {
                delete updatedSelectedData.description;
            }
            // let newList = templateDetails?.cohort_list?.filter((ob) => +ob.template_for === +e.value && +ob.insurance_type === updatedSelectedData?.insurance_type && (([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(updatedSelectedData?.insurance_type)  && ob.payment_status === updatedSelectedData.payment_status) || (updatedSelectedData?.insurance_type === APP_TYPE_WITH_UCF))).map((val) => +val.template_type);
            // templateDetails.templateType = originalTemplateType.filter((ob) => !newList?.includes(+ob.value)) 

            // setTemplateDetails(templateDetails);
            setShowTemplateConfiguration(false);

            leftSideFieldsHandler(updatedSelectedData);

        }
        if (key === 'template_type') {
            setShowEmailField(false);

            if (e.value !== 1) {
                setShowEmailField(true);
            }

            // Email will blank in whatsapp case only
            if (+updatedSelectedData?.template_type === 2) {
                updatedSelectedData.email = '';
                setSelectedTemplateData(updatedSelectedData);
            }


            let newList = templateDetails?.cohort_list?.filter((ob) =>  +ob.insurance_type === updatedSelectedData?.insurance_type && (+ob.template_for === +updatedSelectedData.template_for) && (+ob.template_type === +updatedSelectedData.template_type)).map((val) => +val.payment_status);
            templateDetails.dealerPresent = originalDealerSelect.filter((ob) => !newList?.includes(+ob.value))

        }
        if (key === 'insurance_type') {
            // when user change Insurance Type then it reset Select Template
            if (updatedSelectedData.template_for) {
                delete updatedSelectedData.template_for;
            }
        }

        // if ((updatedSelectedData?.template_for === 1)) {
        //     setShowPermission(HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['sent_to_insurance_broker'], accessType: 'edit' }));
        // }
        // else if ((updatedSelectedData?.template_for === 2)) {
        //     setShowPermission(HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_policy'], accessType: 'edit' }))
        // } 
        // else if(updatedSelectedData?.template_for === 3){
        //     setShowPermission(true);
        // }

        if(errField[key]){
            setErrField((prev)=> ({...prev, [key]:""}))
        }

    }

    // on cancel page re-direct to listing
    const CancelTemplateField = () => {
        if (window.confirm(resetWarningMsg)) {
            navigate('/email-template-config');
        }
    }

    useEffect(() => {
        // Check if 'partner_id' parameters are present
        if (!searchParams.get('partner_id')) {
            navigate('/email-template-config');
        }
    }, [searchParams, navigate]);

    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i); // eslint-disable-line  

    // Function to validate the form
    const validForm = async () => {
        let formIsValid = true;
        setErrField({
            email: "",
            description: "",

        });

        if (selectedTemplateData.email === "" && selectedTemplateData?.template_type === 1 && selectedTemplateData?.template_for === SEND_TO_INS_PARTNER) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                email: "Email is required",
            }));
        }
        else if (!validEmailRegex.test(selectedTemplateData.email) && selectedTemplateData?.template_type === 1 && selectedTemplateData?.template_for === SEND_TO_INS_PARTNER) {
            let multipleEmailChk = selectedTemplateData?.email?.split(',') || [];
            for (const email of multipleEmailChk) {
                if (!validEmailRegex.test(email.trim())) {
                    formIsValid = false;
                    setErrField((prevState) => ({
                        ...prevState,
                        email: "Please enter valid email",
                    }));
                    break;
                }
            }
        }
        if (!selectedTemplateData.payment_status && [SEND_TO_CUST_DOCS, SEND_TO_CUST_CLAIM_CLOSING].includes(selectedTemplateData?.template_for)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                payment_status: "Payment status is required",
            }));
        }
        // let checkValidKeys = formIsValid ? checkFieldKeyExists(originalGroupedData) : {};
        let checkValidKeys =  checkFieldKeyExists(originalGroupedData);

        const regex = /{{(.*?)}}/g;
        const descriptionKeys = selectedTemplateData.description.match(regex); //GET ALL KEYS IN TEXT

        let fieldKeyError = false;
        if ([1,3].includes(+selectedTemplateData.template_for)) { //CUSTOMER SHARE & CLAIM CLOSING

            let unwantedKeys = descriptionKeys && descriptionKeys.filter((x) => {
                if (checkValidKeys && checkValidKeys.sentToBrokerKeys) {
                    return !checkValidKeys.sentToBrokerKeys.includes(x);
                }
                // handle the case when sentToBrokerKeys is undefined
                return false;
            });

            if (unwantedKeys?.length) {
                formIsValid = false;
                fieldKeyError = true;
            }

        } else { //POLICY SHARED 
            let unwantedKeys = descriptionKeys?.filter((x) => !checkValidKeys?.policySharedKeys?.includes(x));

            if (unwantedKeys?.length) {
                formIsValid = false;
                fieldKeyError = true;
            }
        }

        if (fieldKeyError) {
            toast.error("Please check template text, some of the keys are not allowed");
        }

        return formIsValid;
    };


    //GET ALL FIELDS KEYs
    const checkFieldKeyExists = (fieldData) => {
        let sentToBrokerKeys = [];
        let policySharedKeys = [];
        for (const key in fieldData) {
            const topLevelObject = fieldData[key];

            let childsLength = (topLevelObject.child) ? (Array.isArray(topLevelObject.child) ? Object.assign({}, topLevelObject.child) : (topLevelObject.child)) : {};
            if (Object.keys(childsLength).length) {

                let allKeys = checkFieldKeyExists(topLevelObject.child);
                sentToBrokerKeys = [...sentToBrokerKeys, ...allKeys.sentToBrokerKeys]
                policySharedKeys = [...policySharedKeys, ...allKeys.policySharedKeys]

            } else {
                if (topLevelObject.send_to_insurance_broker && +topLevelObject.insurance_policy_shared) {

                    sentToBrokerKeys.push(`{{${topLevelObject.field_key}}}`)
                    policySharedKeys.push(`{{${topLevelObject.field_key}}}`)

                }
                else {
                    if (topLevelObject.send_to_insurance_broker) {
                        sentToBrokerKeys.push(`{{${topLevelObject.field_key}}}`)

                    } else {
                        policySharedKeys.push(`{{${topLevelObject.field_key}}}`)
                    }
                }

            }

        }
        return { sentToBrokerKeys, policySharedKeys };

    };

    const resetHandler = () => {
        // when data is added
        if (selectedTemplateData.partner_id === undefined || selectedTemplateData.partner_id === null) {
            setSelectedTemplateData({ ...selectedTemplateData, template_for: '', template_type: '', email: '', description: '', insurance_type: '' });
        }
        //when data is Edited
        if (selectedTemplateData?.partner_id) {
            setSelectedTemplateData({ ...selectedTemplateData, email: '' });
        }
        setTemplateKey('');


    }

    useEffect(() => {
      //  if (Object.keys(commonState.common).length === 0) {
            dispatch(getMasterConfig(client));
       // }
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <FullPageLoader show={loading} />
            <div className="top-heading-outer">
                <div className="back-opt">
                    <Link to="/email-template-config" title="Back">
                        <i className="ic-arrow_back"></i>
                        Back
                    </Link>
                </div>
                <div className="top-heading">
                    <h1>Send to Insurance Partner Template</h1>
                </div>
                <div className="sub-heading">{templateDetails.financier_name}</div>
            </div>
            <div className="container-fluid">
                <div className="financier-detail-outer">
                    <div className='filter-filed-form-template'>
                        {/* Insurance Type dropdown for Searcing case*/}
                        <ReactSelect
                            options={commonState?.common?.insurance_type || []}
                            value={commonState?.common?.insurance_type?.find((v) => +v.value === +selectedFilters['insurance_type']) || null}
                            handleChange={(e, fieldKey) => handleChange(e, fieldKey)} placeholder="Select Insurance Type" fieldKey="insurance_type"
                        />

                        <ReactSelect options={templateDetails?.templateFor || []}
                            value={templateDetails['templateFor']?.find((v) => +v.value === +selectedFilters['template_for']) || null}
                            handleChange={(e, fieldKey) => handleChange(e, fieldKey)} placeholder="Select Template For" fieldKey="template_for"
                        />

                        <ReactSelect options={originalTemplateType}
                            value={originalTemplateType?.filter((v) => v.value === selectedFilters['template_type']) || null}
                            handleChange={(e, fieldKey) => handleChange(e, fieldKey)} placeholder="Select Template" fieldKey="template_type" key="template_type"
                        />

                        {
                            [SEND_TO_CUST_DOCS,SEND_TO_CUST_CLAIM_CLOSING].includes(selectedFilters['template_for']) &&
                            <ReactSelect options={commonState?.common?.payment_status}
                                value={commonState?.common?.payment_status?.filter((v) => +v.value === +selectedFilters['payment_status']) || null}
                                handleChange={(e, fieldKey) => handleChange(e, fieldKey)} placeholder="Payment Status" fieldKey="payment_status" key="payment_status"
                            />
                        }

                        <div className="btn-submit-reset">
                            {/* <button className="btn-primary" type="submit" disabled={!( (selectedFilters.template_for === 1 && selectedFilters.payment_status) ||   selectedFilters.template_for && selectedFilters.template_type && selectedFilters.insurance_type && (([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(selectedFilters.insurance_type) && selectedFilters.payment_status) || ((selectedFilters.insurance_type === APP_TYPE_WITH_UCF)) || ((selectedFilters.insurance_type === APP_TYPE_AGENCY))))} onClick={submitFilter}>Search</button> */}
                           <button className="btn-primary" type="submit" disabled={!(
                               (selectedFilters?.template_type && selectedFilters?.template_for !== 1 && selectedFilters?.insurance_type) 
                               ||
                               (+selectedFilters?.template_for === 1 && selectedFilters?.payment_status && selectedFilters?.template_type && selectedFilters?.insurance_type)
                           )}
                               onClick={submitFilter}>Search</button>

                            {
                                (HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['email_template_management'], accessType: 'edit' })) &&
                                <button className="btn-line" onClick={addTemplate}>Add New</button>
                            }
                        </div>
                    </div>
                    {showForm &&
                        <>
                            <div className='financier-sub-filter-list'>
                                <div className='financier-top-filter'>
                                    {/* Add extra dropdown for Insurance Type duing Add New case only*/}

                                    <ReactSelect options={commonState?.common?.insurance_type || []}
                                        value={commonState?.common?.insurance_type?.find((v) => +v.value === +selectedTemplateData['insurance_type']) || null}
                                        handleChange={(e, fieldKey) => addTemplateFieldHandler(e, fieldKey)} placeholder="Select Insurance Type" fieldKey="insurance_type" key="insurance_type"
                                        isDisabled={showFiled}
                                    />

                                    <ReactSelect options={templateDetails?.templateFor || []}
                                        value={(templateDetails && templateDetails.templateFor && selectedTemplateData?.template_for) ? templateDetails.templateFor?.find((elm) => +elm.value === selectedTemplateData?.template_for) : null}
                                        handleChange={(e, fieldKey) => addTemplateFieldHandler(e, fieldKey)} placeholder="Select Template" fieldKey="template_for" key="template_for"
                                        isDisabled={showFiled}
                                    />


                                    <ReactSelect options={templateDetails?.templateType || []}
                                        value={(templateDetails && templateDetails.templateType && selectedTemplateData?.template_type) ? templateDetails.templateType?.find((elm) => +elm.value === selectedTemplateData?.template_type) : null}
                                        handleChange={(e, fieldKey) => addTemplateFieldHandler(e, fieldKey)} placeholder="Template Configuration" fieldKey="template_type" key="template_type"
                                        isDisabled={showFiled}
                                    />
                                    {
                                        [SEND_TO_CUST_DOCS, SEND_TO_CUST_CLAIM_CLOSING].includes(selectedTemplateData.template_for) &&
                                        <>
                                            <ReactSelect options={commonState?.common?.payment_status || []}
                                            value={
                                                commonState?.common?.payment_status
                                                    ? commonState?.common?.payment_status.find((option) => 
                                                        option.value === +selectedTemplateData?.payment_status
                                                    )
                                                    : null
                                                }
                                                handleChange={(e, fieldKey) => addTemplateFieldHandler(e, fieldKey)} placeholder="Payment Status" fieldKey="payment_status" key="payment_status"
                                                isDisabled={showFiled}
                                                errorField={errField['payment_status']}
                                                errorClassName="error-txt-partner"
                                            />
                                            {/* {errField.email && (
                                                <span className="error-txt error-txt-partner">
                                                    {errField['payment_status']}
                                                </span>
                                            )} */}
                                        </>
                                    }

                                    {
                                        selectedTemplateData?.template_for === SEND_TO_INS_PARTNER && 
                                            <fieldset className="multiselect-dropDown">
                                                <div className="material animation-effect ">
                                                    <input
                                                        value={selectedTemplateData?.email || ""}
                                                        placeholder={" "}
                                                        className="form-input"
                                                        onChange={(e) => addTemplateFieldHandler(e, 'email')}
                                                        type="text"
                                                        disabled={showEmailField}
                                                        title={selectedTemplateData?.email || ""}
                                                    />
                                                    <label data-label={"Insurance Partner Email ID"} className="form-label"></label>
                                                    {errField.email && (
                                                        <span className="error-txt error-txt-partner">
                                                            {errField['email']}
                                                        </span>
                                                    )}
                                                </div>
                                            </fieldset>
                                    }
                                    

                                    <div className="btn-submit-reset">
                                        <button className="btn-reset" onClick={resetHandler}>Reset</button>
                                    </div>

                                </div>
                            </div>

                            <div className='template-filed-outer'>
                                <div className="swith-txt-outer">
                                    <h3>Template Fields</h3>
                                    <div className="toggle-switch-outer">
                                        {showPermission &&
                                            <div className="toggleApp">
                                                <div className={`ToggleSwitch ${+selectedTemplateData?.status ? 'active' : ''}`} onClick={(e) => handleTemplateData(e, 'status')}>
                                                    <div className={`knob ${+selectedTemplateData?.status ? 'active' : ''}`}></div>
                                                    <div className="toggle-status">Publish</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="templete-list-filed-outer">
                                    <div className="list-left-panel">
                                        {Object.values(groupedData).map((parent) => (
                                            <div key={parent.id}>
                                                <h2>{parent.name}</h2>
                                                <ul>
                                                    {Object.values(parent.child).map((child) => (
                                                        <li key={child.id}
                                                            data-key={`{{${child.field_key}}}`}
                                                            className={(templateKey === child?.field_key) ? "selected-attribute" : ""}
                                                            onClick={(e) => handleKeySelect(e, child)}

                                                        >
                                                            {
                                                                child?.child?.length ?
                                                                    <strong>{child.name}</strong> :
                                                                    <span>{child.name}</span>
                                                            }
                                                            {(child?.child?.length > 0) &&
                                                                <ul>
                                                                    {
                                                                        Object.values(child?.child).map((innerChild) => (

                                                                            <li className={(templateKey === innerChild?.field_key) ? "selected-attribute" : ""} key={innerChild.id} onClick={(e) => handleKeySelect(e, innerChild)} >{innerChild.name}</li>
                                                                        )
                                                                        )
                                                                    }
                                                                </ul>
                                                            }

                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="add-remove-arrow">
                                        <ul>
                                            <li>
                                                <i className="ic-arrow_forward" onClick={handleForwardButtonClick}></i>
                                            </li>
                                            <li><i className="ic-arrow_back" onClick={handleReverseButtonClick}></i></li>
                                        </ul>
                                    </div>
                                    <div className="text-filed-select-area">
                                        <textarea
                                            className=""
                                            placeholder="Select the template field to configure template body"
                                            onChange={handleTextChange}
                                            onBlur={(e) => setSelectionPos(e.target.selectionStart)}
                                            onFocus={(e) => setSelectionPos(e.target.selectionStart)}
                                            id="inputTextArea"
                                            value={selectedTemplateData?.description || ''}
                                        >
                                        </textarea>
                                       
                                    </div>
                                </div>

                                <div className="btn-submit-reset update-btn">
                                    {
                                        showPermission &&
                                        <button className="btn-primary m-sm-r" onClick={(selectedTemplateData?.description?.trim() && selectedTemplateData?.template_type) ? SaveTemplateField : undefined}
                                            disabled={!(selectedTemplateData?.description?.trim() && selectedTemplateData?.template_type && (([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL,3].includes(selectedTemplateData?.insurance_type) ) || [APP_TYPE_WITH_UCF, APP_TYPE_AGENCY,3].includes(selectedTemplateData?.insurance_type)))}
                                        >Update</button>
                                     
                                    }
                                    <button className="btn-line" onClick={CancelTemplateField}>Cancel</button>

                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export default SendToFinancierTemplateDetail;