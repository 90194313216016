import React, { useEffect, useState } from "react"

export default function CustomSearch(props: any) {
    const [show, setShow] = useState(false)
    const [value, setValue] = useState("")
    const [loopData, setLoopData] = useState([])

    const handleClick = () => {
        setShow(true)
    }

    const handleSearch = (searchKeyword: string) => {
        let searchKeys = props?.searchFields || []
        let filteredOption: any = []

        for (let data of props?.data) {
            for (let key of searchKeys) {
                if (data[key] && data[key]?.toString()?.includes(searchKeyword)) {
                    filteredOption.push(data)
                }
            }
        }

        if (filteredOption.length) {
            setLoopData(filteredOption)
        }

        if (!filteredOption?.length) {
            setLoopData([])
        }

        if (!searchKeyword) {
            setLoopData(props.data)
        }
        setValue(searchKeyword)
    }

    useEffect(() => {
        if (props?.data?.length) {
            setLoopData(props.data)
        }
    }, [props?.data])

    return (
        <React.Fragment key={'custom-search'}>
            <div className="col-md-4">
                <div className="add-insurnace-search-outer">
                    <div className="search-bx">
                        <div className="material">
                            <input type="text" autoComplete="off" placeholder=" " name="search_text" className="form-input" title="Search Text" aria-label="Search text" onClick={() => handleClick()} onBlur={() => {
                                setTimeout(() => {
                                    setShow(false)
                                }, 200)
                            }} onChange={(e) => handleSearch(e.target.value)}
                                value={value} />
                            <i className="ic-search"></i>

                            <label data-label={props?.placeholder || ""} className="form-label search-label"></label>

                        </div>
                        {
                            show && loopData?.length
                                ?
                                <div className="searhc-list-outer">
                                    <ul>
                                        {
                                            loopData?.map((element: any, idx: number) => {
                                                return (
                                                    <li key={idx + "_" + element.lead_id} onClick={() => {
                                                        setValue(element.lead_id)
                                                        props?.handleDataClick(element)
                                                    }}>
                                                        <label>Insurance ID {element.lead_id}</label>
                                                        <div className="insurance-detail-list">
                                                            <span className="search-span-one" title={element.customer_name}>{element.customer_name}</span>
                                                            <span className="search-span-two" title={element.plate_number}>{element.plate_number}</span>
                                                            <span className="search-span-three" title={element.expiry_date}>Expiry: {element.expiry_date}</span>
                                                            <span className="search-span-four" title={element.policy_number}>Policy No: {element.policy_number}</span>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                : undefined
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}