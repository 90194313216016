import DocNotification from "@src/component/DocumentNotifications";
import secureStorage from "@src/config/encrypt";
import client from "@src/store/apolloClient";
import { getDashboardData } from "@src/store/slices/claim";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Dashboard(props: any) {
    let result = secureStorage.getItem("authUserInfo");
    const [searchText, setSearchText] = useState("")
    const [applicationCount, setApplicationCount] = useState<Array<any>>([])

    const navigate = useNavigate()

    const fetchDashboardData = async () => {
        const result: any = await getDashboardData(client, {})
        if (result?.data?.length) {
            setApplicationCount(result.data)
        } else {
            toast.error("No data found")
        }
    }

    const handleCountClickNavigation = (statusDetail: any) => {
        navigate({
            pathname: '/claim-list',
            search: `?claim_status=[${statusDetail?.id}]`,
        });
    }

    const handleSearchClick = () => {
        if (!searchText) {
            return toast.error("Please enter claim id or plate number")
        }
        let text = searchText.trim()
        navigate({
            pathname: '/claim-list',
            search: `?searchKeyword=${text}`,
        });
    }

    useEffect(() => {
        fetchDashboardData()
    }, [])

    return (
        <div className="dashboard-bg">
            <div className="dashboard-outer">
                <div className="heading">
                    <span className="subheading">Hello {result?.user_data?.name || ""}</span>
                    <h1>Welcome to Insurance Claim Box</h1>
                </div>
                <div className="search-bx">
                    <input type="text" placeholder="Search by Claim ID or Policy No or Plate No or Customer Name" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                    <button className="search-btn" onClick={handleSearchClick}>Search</button>
                    <i className="ic-search"></i>
                </div>

                <div className='d-flex dashboard-notification-outer'>
                    <div className="dashboard-card">
                        <div className="card">
                            <div className="roung-bg color-one">
                                <i className="ic-application"></i>
                            </div>
                            <span className="count-txt" onClick={() => handleCountClickNavigation(applicationCount?.[0])}>{applicationCount?.[0]?.count}</span>
                            <span className="card-txt">{applicationCount?.[0]?.name}</span>
                        </div>

                        <div className="card">
                            <div className="roung-bg color-four">
                                <i className="ic-document-uploaded"></i>
                            </div>
                            <span className="count-txt" onClick={() => handleCountClickNavigation(applicationCount?.[1])}>{applicationCount?.[1]?.count}</span>
                            <span className="card-txt">{applicationCount?.[1]?.name}</span>
                        </div>

                        <div className="card">
                            <div className="roung-bg color-two ">
                                <i className="ic-send-to-partner"></i>
                            </div>
                            <span className="count-txt" onClick={() => handleCountClickNavigation(applicationCount?.[2])}>{applicationCount?.[2]?.count}</span>
                            <span className="card-txt">{applicationCount?.[2]?.name}</span>
                        </div>

                        <div className="card">
                            <div className="roung-bg color-six">
                                <i className="ic-evaluation-received"></i>
                            </div>
                            <span className="count-txt" onClick={() => handleCountClickNavigation(applicationCount?.[3])}>{applicationCount?.[3]?.count}</span>
                            <span className="card-txt">{applicationCount?.[3]?.name}</span>
                        </div>

                        <div className="card">
                            <div className="roung-bg color-six">
                                <i className="ic-evaluation-received"></i>
                            </div>
                            <span className="count-txt" onClick={() => handleCountClickNavigation(applicationCount?.[4])}>{applicationCount?.[4]?.count}</span>
                            <span className="card-txt">{applicationCount?.[4]?.name}</span>
                        </div>

                        <div className="card">
                            <div className="roung-bg color-four">
                                <i className="ic-softapproved">
                                    <i className="path1"></i>
                                    <i className="path2"></i>
                                    <i className="path3"></i>
                                    <i className="path4"></i>
                                    <i className="path5"></i>
                                    <i className="path6"></i>
                                    <i className="path7"></i>
                                    <i className="path8"></i>
                                </i>
                            </div>
                            <span className="count-txt" onClick={() => handleCountClickNavigation(applicationCount?.[5])}>{applicationCount?.[5]?.count}</span>
                            <span className="card-txt">{applicationCount?.[5]?.name}</span>
                        </div>

                        <div className="card">
                            <div className="roung-bg color-two">
                                <i className="ic-claim-closing"></i>
                            </div>
                            <span className="count-txt" onClick={() => handleCountClickNavigation(applicationCount?.[6])}>{applicationCount?.[6]?.count}</span>
                            <span className="card-txt">{applicationCount?.[6]?.name}</span>
                        </div>

                        <div className="card">
                            <div className="roung-bg color-one">
                                <i className="ic-transfer-proof"></i>
                            </div>
                            <span className="count-txt" onClick={() => handleCountClickNavigation(applicationCount?.[7])}>{applicationCount?.[7]?.count}</span>
                            <span className="card-txt">{applicationCount?.[7]?.name}</span>
                        </div>

                        <div className="card">
                            <div className="roung-bg color-three">
                                <i className="ic-lost">
                                    <i className="path1"></i>
                                    <i className="path2"></i>
                                    <i className="path3"></i>
                                    <i className="path4"></i>
                                </i>
                            </div>
                            <span className="count-txt" onClick={() => handleCountClickNavigation(applicationCount?.[8])}>{applicationCount?.[8]?.count}</span>
                            <span className="card-txt">{applicationCount?.[8]?.name}</span>
                        </div>
                    </div>
                    <div className='doc-notification'>
                        <h2>Document Notifications</h2>
                        <DocNotification callFrom={"dashboard"} />
                    </div>

                </div>


            </div>
        </div>
    )
}