
import { API_URL } from '../config/constant';
import secureStorage from "../config/encrypt";

export function executeGraphQLQuery(query, client, variables = {}, headers = {}) {

  return client.query({
    query: query,
    variables,
    context: headers
  })
    .then(res => {
      if (res?.errors?.[0]?.statusCode === 403 ||
        res?.errors?.[0]?.message.includes('Invalid Token')) {
        localStorage.clear();
        window.location.href = '/login';
      }
      return res;
    })
    .catch(err => {
      console.log(err)
      const { networkError } = err;
      if (networkError.statusCode === 401) {
        localStorage.clear()
        window.location.href = '/login';
      }
    });
}


export function executeGraphQLMutation(mutation, variables, client) {

  return client.mutate({
    mutation: mutation,
    variables,
  })
}

export async function executeUpload(formData) {
  try {
    let authInfo = secureStorage.getItem('authUserInfo');
    const user_info = (authInfo);
    const token = user_info?.access_token;
    const headers = {
      'x-apollo-operation-name': 'true',
    }

    if (token) {
      headers['Authorization'] = token
    } else {
      headers['apikey'] = API_URL.API_KEY
    }

    const response = await fetch(`${API_URL.GETWAY_API}api/v1`, {
      method: 'POST',
      headers,
      body: formData,
      signal: AbortSignal.timeout(300000)
    });
    
    const res = await response.json();

    //In case token expire while uploading, it will redirect to 
    //login page and clear tokens in localstorage.
    if (res?.status === 401) {
      localStorage.clear();
      window.location.href = '/login';
    }

    return res;
  } catch (error) {    
    return error
  }
}


