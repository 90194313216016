import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MASTER_CONFIG } from "@src/services/templatedata.gql";
import client from '../apolloClient';

const common = createSlice({
    name: 'common',
    initialState: {
        common: {},
        mmvList:[],
        regionList: []
    },
    reducers: {
        setMasterConfig: (state, action: PayloadAction<any>) => {
            state.common = action.payload
        },

        mmvList: (state, action) => {
            state.mmvList = action.payload;
        },

        regionList: (state, action) => {
            state.regionList = action.payload
        }
    }
});


export default common.reducer

const { setMasterConfig , mmvList, regionList } = common.actions

// export const setMasterConfigData = (data: any) => async (dispatch: any) => {
    // dispatch(setMasterConfig(data))

// }

export const getMasterConfig = () => (dispatch: any) => {
	return new Promise((resolve, reject) => {
			// executeGraphQLQuery(MASTER_CONFIG(), client).then((response: any) => {
        client.query({ query: MASTER_CONFIG() })
        .then(
            (response:any)=>{
               if (response && response.data && response.data.masterConfig) {
					let result = response.data.masterConfig.data;
					dispatch(setMasterConfig(result))
					resolve(result)
				} else {
					dispatch(setMasterConfig({}))
					resolve({})
				}
			},
            (error:any) => {
                reject(error);
            
            });
	});
}

export const setMMVList = (data: any) => async (dispatch: any) => {
    dispatch(mmvList({ ...data }))
}

export const setRegionList = (data: any) => async (dispatch: any) => {
    dispatch(regionList(data))
}