import { gql } from '@apollo/client';

export const GET_CLAIM_LIST_DATA = () => {

    return gql`
    query getAlClaimList($claim_input: ClaimInputs!) { 
        getAlClaimList(claim_input: $claim_input) {  
          message  
               pagination{
                cur_page
                limit
                next_page
                pages
                prev_page
                total
            },
            status
            data{
            claim_id,
            insurance_id,
            insurance_type,
            partner_id,
            policy_number,
            claim_type,
            third_party_name,
            third_party_mobile,
            remarks,
            status_id,
            make_name,
            model_name,
            version_name,
            plate_number,
            version_name,
            status_name,
            partner_name,
            insurance_type_name,
            customer_name,
            assign_user_id
            hash_id
            }
            }
        }
    `;
};




export const UPDATE_CLAIM_ASSIGN_USER = () => {

    return gql`mutation updateClaimAssignUser($claim_input: UpdateAssignUserInput!) {
     updateClaimAssignUser(claim_input: $claim_input) {
             data {
      claim_id
      insurance_id
      insurance_type
      partner_id
      policy_number
      claim_type
      third_party_name
      third_party_mobile
      remarks
      status_id
      make_name
      model_name
      version_name
      plate_number
      version_name
      status_name
      partner_name
      insurance_type_name
      __typename
    }
            message
           status 
    }
}`
};



export const EXPORT_GET_CLAIM_LIST_DATA = () => {

    return gql`
    query exportGetAllClaimList($claim_input: ClaimInputs!) { 
        exportGetAllClaimList(claim_input: $claim_input) {  
            message   
            status
            }
        }
    `;
};


export const GET_CLAIM_RIBBON_DATA = () => {
    return gql`
    query getClaimRibbonData($claim_id: String!){
        getClaimRibbonData(claim_id: $claim_id) {
            status
            message
            data {
                data {
                    title
                    key
                    value
                }
                history
            }
        }
    }`;
};


export const GET_CLAIM_PROCESSING_DATA = (type) => {
    let dataFormat = ``
    if (type === 'applicationDetails') {
        dataFormat = `{
                ... on ClaimApplicationDetailsSchema {
                    id
                    insurance_id
                    insurance_type
                    partner_id
                    policy_number
                    effectivity_date
                    expiry_date
                    claim_type
                    third_party_name
                    third_party_mobile
                    remarks
                    status_id
                    vehicle_type_id
                    make
                    model
                    version
                    make_name
                    model_name
                    version_name
                    plate_number
                    engine_number
                    chassis_number
                    mfg_year
                    name
                    mobile
                    email
                    region 
                    partner_hash_id
                    evaldate
                    assign_user_id
                }
            }`
    } 
    else if(type === 'transferProof'){
        dataFormat = `{
            ... on ClaimTransferDetailsSchema {
                id
                claim_id
                claim_closing_date
                car_released_cust
                updated_at
                remarks
                type
                documents { 
                    id
                    transfer_proof_id
                    doc_path
                    status
                    __typename
                }
            }
        }`;
        
    }
    else {
        dataFormat = `{
            ... on ClaimDocumentsWrapperSchema {
                documents {
                    value
                    slug
                    label
                    is_required
                    min_document
                    max_document
                    display_order
                    images {
                        document_id
                        doc_path
                        id
                        source
                        is_resubmit
                    }
                }
            }
        }`
    }

    return gql`
    query fetchClaimProcessingData($claim_id: String!, $api_type: String!, $type: Float){
        fetchClaimProcessingData(claim_id: $claim_id, api_type: $api_type, type: $type) {
            status
            message
            data ${dataFormat}
        }
    }`
}

export const GET_CLAIM_ID_DETAIL = () => {

    return gql`
    query getClaimIdListDetail($claim_id: String!) {
  getClaimIdListDetail(claim_id: $claim_id) {
    message
   
    status
    data {
        data {
            claim_id
            insurance_id
            insurance_type
            partner_id
            policy_number
            claim_type
            third_party_name
            third_party_mobile
            remarks
            status_id
            make_name
            model_name
            version_name
            plate_number
            status_name
            partner_name
            insurance_type_name
            customer_name
            assign_user_id
            created_at
            claim_history {
                claim_id
                status_id
                name
                history_created_date
            }
        }
        pendingDocuments {
            name
            doc_id
        }
    }
  }
}
    `;
};


export const UPLOAD_FILE = () => {
    return gql
        ` mutation ($file: [Upload!], $claim_id: String!, $documents: String!, $api_type: Float!) {
        uploadFile(fileInput: {images: $file, claim_id: $claim_id, documents: $documents, api_type: $api_type}) {
            status
            message
            data {
                addedDocument {
                    document_id
                    doc_path
                    id
                    source
                }
                updatedDocument {
                    document_id
                }
                deletedDocument {
                    id
                    status
                }
                doc_status_id
            }
            error
        }
    }`
}

export const GET_DOCUMENTS_FOR_CUSTOMER_UPLOAD = () => {
    return gql`
    query getDocumentForCustomer($claim_id: String!){
        getDocumentForCustomer(claim_id: $claim_id) {
            status
            message
            data {
                id
                slug
                name
                is_required
                max_document
                min_document
                label
                value
                is_uploaded
                is_requested
                is_resubmit
                remarks
                images {
                    document_id
                    doc_path
                    id
                    source
                    is_resubmit
                }
            }
        }
    }`;
};



export const UPLOAD_FILE_FOR_CUSTOMER = () => {
    return gql
        ` mutation ($file: [Upload!], $claim_id: String!, $documents: String!) {
        uploadFileForCustomer(fileInput: {images: $file, claim_id: $claim_id, documents: $documents}) {
            status
            message
            data {
                addedDocument {
                    document_id
                    doc_path
                    id
                    source
                }
                updatedDocument {
                    document_id
                }
                deletedDocument {
                    id
                    status
                }
                doc_status_id
            }
        }
    }`
}

export const GET_DASHBOARD_DATA = () => {
    return gql`
    query getDashboardCount{
        getDashboardCount {
            status
            message
            data {
                id
                name
                count
            }
        }
    }`;
};


export const GET_DOCUMENT_NOTIFICATION = () => {
    return gql`
    query getDocumentNotifications{
        getDocumentNotifications {
            status
            message
            data {
                description
                created_time
                document_id
                claim_id
                type
                claim
            }
        }
    }`;
};


export const GET_LEAD_HISTORY = () => {
    return gql`
   mutation getLeadHistory($claim_input: MarkAsLostInput!) {
  getLeadHistory(claim_input: $claim_input) {
    message
    status
     data {
      claim_id
      status_id
      history_created_date
      status_name
      sub_status_name
      sub_status_id
      created_by_name
      remarks
    }
    
    __typename
  }
}
    `;
};


export const SAVE_LEAD_MARK_LOST = () => gql`
  mutation saveLeadMarkedLost($claim_input: MarkAsLostInput!) {
  saveLeadMarkedLost(claim_input: $claim_input) {
    message
    status
    data {
        status_id
    }
    
    __typename
  }
}`;
export const GET_CLAIM_CLOSING_DETAILS = () => {
    return gql`
    query getClaimClosingDetails($claim_id: String!){
        getClaimClosingDetails(claim_id: $claim_id) {
            status
            message
            data {
                cash_settlement_done
                bank_account_no
                name_on_bank
                bill_amount
                remarks
            }
        }
    }`;
};


export const UPLOAD_FILE_CLAIM_CLOSING = () => {
    return gql
        ` mutation ($file: [Upload!], $claim_id: String!, $documents: String!, $claim_closing_details: String!) {
        uploadFile(fileInput: {images: $file, claim_id: $claim_id, documents: $documents, claim_closing_details: $claim_closing_details}) {
            status
            message
            data {
                status_id
                addedDocument {
                    document_id
                }
                updatedDocument {
                    document_id
                }
                deletedDocument {
                    id
                    status
                }
            }
        }
    }`
}


export const SAVE_CNC_TRANSFER_PROOF = () => {
    return gql
        `mutation saveTransferCncProof($claim_input: TransferProofInput!) {
        saveTransferCncProof(claim_input: $claim_input) {
            message
            status
            data {
            claim_id
            remarks
            car_released_cust
            claim_closing_date
            type
            id
            document {
                file_name
                file_url
                file_size
            }
        }
            status_id
            
            __typename
        }
    }`
}


export const MARK_DOC_RESUBMIT = () => {
    return gql
        `mutation ($document_id: Float!, $claim_id: String!, $api_type: String!, $comment: String!) {
        requestDocResubmission(document_id: $document_id, claim_id: $claim_id, api_type: $api_type, comment: $comment) {
            status
            message
            data
        }
    }`
}


export const SAVE_DOC_UPLOAD_CLAIM_CLOSING_DETAILS = () => {
    return gql
        `mutation($claim_id: String!, $type: Float!, $claim_closing_details: ClaimClosingInput) {
            saveDocUploadClaimClosingSection(claim_id: $claim_id, type: $type, claim_closing_details: $claim_closing_details) {
                status
                message
                error
                data {
                    status_id
                }
            }
        }`
}