import { detectMimeType, detectVideoMimeType, toBase64, validateFileInput, validateVideoInput } from '@src/config/helper';
import client from '@src/store/apolloClient';
import { getDocumentForCustomer } from '@src/store/slices/claim';
import { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { toast } from 'react-toastify';
import { UPLOAD_FILE_FOR_CUSTOMER } from '@src/services/claimdata.gql';
import { executeUpload } from '@src/common/executeGraphQLQuery';
import { print } from 'graphql'
import FullSreenPreview from '@common/FullscreenPreview';
import { ACCIDENT_VEHICLE_PHOTOS, ALLOWED_VIDEO_DURATION, DOC_UPLOAD_VALIDATION, DOC_VIDEO_RECORDINGS, STATUS_SUB_STATUS, VIDEO_UPLOAD_LIMIT_ACCIDENT_VEHICLE_VIDEOS } from '@src/config/constant';
import { times } from 'lodash';
import React from 'react';


export default function UploadDocClaim(props: any) {
    let { setShowUploadStatus, claim_id, setLoader, claimDetails, pendingDocumentList, updatePendingDocuments } = props

    const [documents, setDocuments] = useState<any>([])
    const [deletedDoc, setDeletedDoc] = useState<any>(null) // eslint-disable-line
    const [previewImgs, setPreviewImgs] = useState([])
    const [showPreviewBox, setShowPreviewBox] = useState(false)
    const [activeImgIndx, setActiveImgIndx] = useState(-1)
    const [pendingVideo, setPendingVideo] = useState<any>({})

    const goBack = () => {
        setShowUploadStatus(false)
    }

    const getDocuments = async () => {
        setLoader(true)
        let variables = { claim_id }
        const result: any = await getDocumentForCustomer(client, variables)
        if (result?.data?.length) {
            setDocuments(result.data)
        }
        setLoader(false)
    }

    const checkVideoDuration = (file: any) => {
        return new Promise((resolve, reject) => {
            let duration = 0;
            const videoElement = document.createElement("video");
            videoElement.preload = "metadata";
            videoElement.src = URL.createObjectURL(file);

            videoElement.onloadedmetadata = () => {
                duration = videoElement.duration
                return resolve(+Math.ceil(+duration))
            };
        })
    }

    const checkVideoCount = async (category: number) => {
        let count: number = 0; //considering currently uploading 1 video

        const files = documents?.find((e: any) => e.id === category)?.images

        for (let file of files) {

            let image = file?.doc_path
            let splitName = image?.split('?')
            let splitPathName = splitName?.[0]?.split('.')
            let fileType = (splitPathName[splitPathName.length - 1])?.toLowerCase()

            let isVideo = ['mp4', 'mov'].includes(fileType)
            if (isVideo) count++
        }

        return count

    }

    const handleInputChange = async (e: any, fieldItem: any) => {
        let selectedFile = e.target.files;

        //code to allow only video from video recordings
        let validateFile = null

        if (fieldItem.slug === 'video_recordings') {
            validateFile = await validateVideoInput(selectedFile)
            for (let file of selectedFile) {
                let videoDuration: any = await checkVideoDuration(file)
                if (videoDuration > ALLOWED_VIDEO_DURATION) {
                    validateFile = true
                    toast.error(`Duration of the video should be within ${ALLOWED_VIDEO_DURATION} seconds`)
                    break
                }
            }
        }

        else if (fieldItem.slug === 'accident_vehicle_photo_video') {
            setLoader(true)
            let videoCount: number = 0;
            const validMimeTypes = ["video/mp4", "video/quicktime"]

            for (let file of selectedFile) {
                const fileSize = file.size
                if (fileSize < DOC_UPLOAD_VALIDATION['minSize'] || fileSize > DOC_UPLOAD_VALIDATION['maxSize']) {
                    validateFile = true
                    toast.error("file size should be between 25kb to 5mb")
                    break
                }
                let base64File = await toBase64(file)
                let isValidImage = await detectMimeType(base64File, {}) // check if file is a valid img

                if (!isValidImage) {
                    let isValidVideo = await detectVideoMimeType(base64File, {}) // check if file is valid video
                    if (!isValidVideo && !validMimeTypes.includes(file.type)) {
                        validateFile = true
                        toast.error('File Format is not supported')
                        break
                    }
                    let videoDuration: any = await checkVideoDuration(file)
                    if (videoDuration > ALLOWED_VIDEO_DURATION) {
                        validateFile = true
                        toast.error(`Duration of the video should be within ${ALLOWED_VIDEO_DURATION} seconds`)
                        break
                    }
                    videoCount++;
                }
            }

            if (!validateFile) {
                let existingVideoCount = await checkVideoCount(fieldItem.value)
                if ((existingVideoCount + videoCount) > VIDEO_UPLOAD_LIMIT_ACCIDENT_VEHICLE_VIDEOS) {
                    validateFile = true
                    toast.error(`Maximum ${VIDEO_UPLOAD_LIMIT_ACCIDENT_VEHICLE_VIDEOS} Videos are allowed`)
                }
            }
            setLoader(false)
        }

        else {
            validateFile = await validateFileInput(selectedFile, [])
        }

        if (validateFile) {
            e.target.value = null
            return
        }

        const maxSelectedFiles = fieldItem?.['images']?.length ? Array.from(fieldItem?.['images']?.filter((e: any) => e.is_resubmit !== 1)).concat(Array.from(selectedFile)) : selectedFile;

        if (fieldItem.max_document) {
            if (maxSelectedFiles.length > fieldItem.max_document) {
                toast.error(`Maximum ${fieldItem.max_document} documents are allowed`)
                e.target.value = null

            } else {
                setLoader(true)
                await handleFileUpload(selectedFile, fieldItem)
                e.target.value = null
            }
        }
    }

    const handleRemoveDocument = async (fieldItem: any, imgDetail: any) => {
        setLoader(true)
        setDeletedDoc(imgDetail)
        await handleFileUpload([], fieldItem, imgDetail)
    }

    const handleImgClick = (imgs: any, idx: number) => {
        let fileName = imgs?.[idx]?.doc_path?.split('?')[0]

        fileName = fileName.split('/')
        fileName = fileName[fileName.length - 1]
        let extension = fileName.split('.')
        extension = extension[extension.length - 1]

        if (extension === 'pdf') return

        setShowPreviewBox(true)
        setPreviewImgs(imgs)
        setActiveImgIndx(idx)
    }

    const handleFileUpload = async (files: any, fieldItem: any, deleteImgDetail?: any) => {
        let finalDocuments: any = []
        let filesArray: any = []

        for (let img of files) {
            let obj = {
                document_id: +fieldItem.value,
                type: 'add'
            }
            finalDocuments.push(obj)
            filesArray.push(img)

            //update pending docs in track claim screen
            let pendingDocs = [...pendingDocumentList]
            pendingDocs = pendingDocs?.filter((e: any) => +e.doc_id !== +obj.document_id)
            updatePendingDocuments(pendingDocs)
        }

        let fileName = filesArray?.[0]?.name
        let extension = fileName?.split('.')
        extension = extension?.[extension.length - 1]?.toLowerCase()
        let isVideo = ['mp4', 'mov'].includes(extension)
        let curVideoCount: any = null

        if (isVideo) {
            setLoader(false);
            setPendingVideo((prev: any) => {
                curVideoCount = (pendingVideo[fieldItem.value] || 0) + 1
                return { ...prev, [fieldItem.value]: (pendingVideo[fieldItem.value] || 0) + 1 }
            })
        }

        //case when customer is deleting
        if (deleteImgDetail?.document_id) {
            finalDocuments.push({ document_id: +fieldItem.value, type: 'delete', id: deleteImgDetail.id })
        }

        const formData = new FormData();

        const operations = JSON.stringify({
            query: print(UPLOAD_FILE_FOR_CUSTOMER()),
            variables: {
                file: filesArray.map(() => null),
                claim_id,
                documents: JSON.stringify(finalDocuments),
            }
        });

        const map: any = {};
        filesArray.forEach((_: any, index: any) => {
            map[index] = [`variables.file.${index}`];
        });

        formData.append('operations', operations);
        formData.append('map', JSON.stringify(map));

        filesArray.forEach((file: any, index: any) => {
            formData.append(index.toString(), file);
        });

        try {
            const result = await executeUpload(formData)
            let { status, data } = result?.data?.uploadFileForCustomer

            if (status && status === 200) {
                if (data?.addedDocument?.length) {
                    let updatedDocuments = documents?.map((e: any) => {
                        let doc = { ...e }

                        //resubmission case handle
                        if (e.id === +fieldItem.value && e.is_resubmit) {
                            doc['is_resubmit'] = 0
                            doc['images'] = []
                        }

                        if (e.id === +fieldItem.value) {
                            doc['images'] = [...doc['images'], ...data.addedDocument]
                            return doc
                        } else {
                            return doc
                        }
                    })

                    setDocuments(updatedDocuments)
                    toast.success("File Uploaded Successfully")
                }

                if (data?.deletedDocument?.length) {
                    let updatedDocuments = documents?.map((e: any) => {
                        let doc = { ...e }
                        if (e.id === deleteImgDetail?.document_id) {
                            doc['images'] = doc['images']?.filter((el: any) => el.id !== deleteImgDetail?.id)
                            //check if we deleted last image & mark is_requested as 1
                            if (!doc?.['images']?.length) {
                                doc['is_requested'] = true
                                let updatedPendingDocs = [...pendingDocumentList, { name: doc.label, doc_id: doc.id }]
                                updatePendingDocuments(updatedPendingDocs)
                            }
                            return doc
                        } else {
                            return doc
                        }
                    })

                    setDocuments(updatedDocuments)
                    setDeletedDoc(null)
                    toast.success("File Deleted Successfully")
                }
            } else if(status && status === 400) {
                toast.error(`Error in uploading file${filesArray?.length > 1 ? 's' : ''}`)
            } else {
                console.log("RESPONSE----->", result)
            }
        } catch {
            toast.error(`Error in uploading file${filesArray?.length > 1 ? 's' : ''}`)
        } finally {
            if (isVideo) {
                setPendingVideo((prev: any) => {
                    let updated = { ...prev }
                    let currCount = (curVideoCount || 0) - 1

                    if (currCount <= 0) delete updated?.[fieldItem.value]
                    else prev[fieldItem.value] = currCount

                    return updated
                })
            }
            setLoader(false);
        }
    }

    useEffect(() => {
        getDocuments()
        //eslint-disable-next-line
    }, [])

    return (
        <>
            {
                showPreviewBox ?
                    <FullSreenPreview open={showPreviewBox} images={previewImgs} onClose={() => setShowPreviewBox(false)} index={activeImgIndx} />
                    : undefined
            }
            <div className='track-insurance-outer h-100'>
                <div className='track-insurance-inner-content'>
                    <div className='claim-status-heading p-lg-b'>
                        <div className='heading-back-arrow'>
                            <i className='ic-arrow_back' onClick={goBack}></i>
                            <h2>Upload Documents</h2>
                        </div>

                    </div>

                    {
                        documents?.filter((e: any) => e.is_uploaded)?.map((doc: any, idx: number) => {
                            return (
                                <div className={`uplaod-doc-card bg-${idx + 1}`} key={doc.slug + "_" + idx}>
                                    <div className='uplad-doc-heading'>
                                        <i className='ic-application'></i>
                                        <h3>{doc.label}</h3>
                                    </div>
                                    <div className='upload-doc-btn'>
                                        <i className='ic-check'></i>
                                        Uploaded
                                    </div>
                                </div>
                            )
                        })
                    }

                    {
                        documents?.filter((e: any) => e.is_requested || e.is_resubmit || (e?.images?.find((el: any) => el.source === 2)))?.length
                            ?
                            <div className='doc-accordain-list'>
                                <Accordion allowZeroExpanded={true}>
                                    {
                                        documents?.filter((e: any) => e.is_requested || e.is_resubmit || (e?.images?.find((el: any) => el.source === 2)))?.map((doc: any, idx: number) => {
                                            let isResubmit = doc.is_resubmit
                                            let customerUploadedImages = isResubmit ? [] : doc.images
                                            const isVideoRecordingDoc = doc?.value === DOC_VIDEO_RECORDINGS
                                            const isAccidentVehiclePhotoVideos = doc?.value === ACCIDENT_VEHICLE_PHOTOS


                                            return (
                                                <AccordionItem key={doc.slug + "_" + idx}>
                                                    <AccordionItemHeading className={`uplaod-doc-card bg-${idx + 1}`}>
                                                        <AccordionItemButton>
                                                            <div className='uplad-doc-heading'>
                                                                <i className='ic-application'></i>
                                                                <h3>{doc.label + (doc.is_required ? "*" : '')}</h3>
                                                            </div>
                                                            {
                                                                customerUploadedImages?.length
                                                                    ?
                                                                    <div className='uploaded-tag'>
                                                                        <i className='ic-check'></i>
                                                                    </div>
                                                                    : undefined
                                                            }
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    {
                                                        isResubmit
                                                            ?
                                                            <div className={"resubmission-div"}>
                                                                <span>Requested For Resubmission</span><br />
                                                                <div className='resub-icon-section'><img src='/comment.svg' alt='comment icon' />
                                                                    <span>
                                                                        {doc.remarks}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            : undefined
                                                    }
                                                    <AccordionItemPanel>
                                                        <div className='accordain-conent-outer'>
                                                            {
                                                                customerUploadedImages?.map((img: any, indx: number) => {
                                                                    let image = img?.doc_path
                                                                    let splitName = image?.split('?')
                                                                    let splitPathName = splitName?.[0]?.split('.')
                                                                    let fileType = (splitPathName[splitPathName.length - 1])?.toLowerCase()
                                                                    fileType = fileType === 'csv' ? 'excel' : (fileType === 'doc' || fileType === 'docx') ? fileType = 'word' : fileType

                                                                    return (
                                                                        <div className="img-bx-select-opt" key={"CUSTOMER_IMG_" + indx}>
                                                                            <div className="image-bx" onClick={() => handleImgClick(customerUploadedImages, indx)}>
                                                                                {
                                                                                    fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' ?
                                                                                        <img src={image} alt={"document_image_" + indx} />
                                                                                        :
                                                                                        fileType === 'mp4' || fileType === 'mov' || fileType === 'webm'
                                                                                            ?
                                                                                            <img src={'/play3.svg'} className='video-icon-size' alt='video-play-icon' />
                                                                                            :
                                                                                            <a href={image} target="_blank" rel="noreferrer"><i className={`ic-${fileType}-file icons-normal video-icon-size`}></i></a>
                                                                                }
                                                                            </div>
                                                                            {
                                                                                (claimDetails?.status_id < STATUS_SUB_STATUS['status']['document-uploaded'])
                                                                                    ||
                                                                                    (claimDetails?.status_id === STATUS_SUB_STATUS['status']['loa-received'])
                                                                                    ?
                                                                                    <div className="close-icn icon-top-right">
                                                                                        <i className="ic-clearclose" onClick={() => handleRemoveDocument(doc, img)}></i>
                                                                                    </div>
                                                                                    : undefined
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                pendingVideo?.[doc.id]
                                                                    ?
                                                                    <div className="img-bx-select-opt">
                                                                        <div className="image-bx">
                                                                            {
                                                                                times(pendingVideo[doc.id], (idx: number) => {
                                                                                    return (
                                                                                        <React.Fragment key={`video-${idx}`}>
                                                                                            <img src={'/play3.svg'} className='video-icon-size' alt='video-play-icon' />
                                                                                            <div className="video-loader"></div>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    : undefined
                                                            }

                                                            {
                                                                customerUploadedImages?.length < doc.max_document && ((claimDetails?.status_id < STATUS_SUB_STATUS['status']['document-uploaded'])
                                                                    ||
                                                                    (claimDetails?.status_id === STATUS_SUB_STATUS['status']['loa-received']))
                                                                    ?
                                                                    <div className="img-bx-select-opt">
                                                                        <div className="image-bx more-file-upload">
                                                                            <i className='ic-add'></i>
                                                                            <input type="file" name="" onChange={(e) => handleInputChange(e, doc)} />
                                                                            <p>{isAccidentVehiclePhotoVideos ? 'Add Photo/Video' : isVideoRecordingDoc ? "Add Video" : "Add Photo"}</p>
                                                                        </div>

                                                                    </div>
                                                                    : undefined
                                                            }
                                                        </div>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            )
                                        })
                                    }
                                </Accordion>
                            </div>
                            : undefined
                    }
                </div>
            </div>
        </>

    )
}