import React, { useEffect, useMemo, useState } from "react";
import { Tab, Nav } from 'react-bootstrap';
import ToggleSwitch from "../../common/ToggleSwitch";
import _ from 'lodash';
import { saveRoleData } from "@store/slices/RoleUserSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as HELPER from '@src/config/helper';
import { ROLE_FEATURES } from '@src/config/constant';
/* eslint-disable jsx-a11y/anchor-is-valid */
function RoleListEdit(params: any) {
    const dispatch: any = useDispatch();
    let { showEditPopUp, showPopup, editRoleData, roleUserList, featureList } = params;
    const [errors, setError] = useState<any>({});
    const [formData, setFormData] = useState<any>({});
    let [user_list, setUserList] = useState<any>([]);
    let [feature_list, setFeatureList] = useState<any>([]);
    let [recentAddId, setRecentAddId] = useState<any>([]);
    let [recentRemoveId, setRecentRemoveId] = useState<any>([]);
    let [recentAddFeature, setRecentAddFeature] = useState<any>([]);
    let [changeRoleName, setChangeRoleName] = useState<any>([]);
    let [changeStatus, setchangeStatus] = useState<any>([]);

    useEffect(() => {

        setFormData({
            ...editRoleData,
            search_feature: '',
            search_user: ''
        });
        getUserList();
        getFeatureList();
        // eslint-disable-next-line
    }, []);


    const handleChange = async (key: string, event: any, val: any) => {

        if (key === 'status') {
            formData[key] = Number(formData.status) === 1 ? 0 : 1;
            if (!changeStatus.includes(key)) {
                setchangeStatus([...changeStatus, key])
            }
        }
        else if (key === 'user_ids') {
            let isKeyExist: any = _.findKey(user_list, function (v: any) {
                return (
                    Number(v.value) === Number(val)
                );
            });

            if (isKeyExist) {
                user_list[isKeyExist].isChecked = event.target.checked;
            }
            setUserList([...user_list]);
            // if value is checked or unChecked, value store in state
            if (event.target.checked) {
                if (!recentAddId.includes(val)) {
                    setRecentAddId((prevIds: any) => [...prevIds, val]);
                }
            } else {
                if (!recentRemoveId.includes(val)) {
                    setRecentRemoveId((prevIds: any) => [...prevIds, val]);
                }
            }
        } else if (key === 'feature_data') {
            let isKeyExist = _.findKey(feature_list, function (v) {
                return (
                    Number(v.value) === Number(val)
                );
            });
            if (isKeyExist) feature_list[isKeyExist].isChecked = event.target.checked;

            //FIND CHILD IF ANY
            // feature_list = feature_list.map((child:any)=> {
            //     return +child.parent_id === +val ? {...child, isChecked: event.target.checked} : child
            // });

            // setFeatureList([...feature_list])


        }
        else if (key === 'additional_feature_radio') {
            let isKeyExist = _.findKey(feature_list, function (v) {
                return (
                    Number(v.value) === Number(val)
                );
            });
            if (isKeyExist) feature_list[Number(isKeyExist)].access_type = Number(feature_list[isKeyExist].access_type) === 1 ? 2 : 1;
            if (!recentAddFeature.includes(val)) {
                setRecentAddFeature([...recentAddFeature, val]);
            }

        } else if (event.target) {
            formData[key] = event.target.value;
            if (!changeRoleName.includes(key)) {
                setChangeRoleName([...changeRoleName, key])
            }
        }
        setFormData({ ...formData });
    }

    const handleSubmit = async (e: any) => {
        // convert string into array
        const editRoleDataString = params.editRoleData.user_ids;
        const editRoleDataArray = editRoleDataString?.split(",").map(Number);

        // check iss any changes make in list or not, compare their lenght

        let hasUncheckedButEditableRole = user_list
            .filter((user: any) => user.isChecked);

        let checkLength: any = '';
        if (hasUncheckedButEditableRole?.length === editRoleDataArray?.length) {
            checkLength = true;
        } else {
            checkLength = false;
        }
        e.preventDefault();
        let postData = await getFormData();
        // for match original object to update object , to check any changes make or not
        let transformedEditRoleData: any = {
            role_name: params.editRoleData.roleName,
            role_description: params.editRoleData.description,
            status: params.editRoleData.status
        };

        const keysToCompare = ['role_name', 'role_description', 'status'];
        let isEqual: any = keysToCompare.every((key: any) => transformedEditRoleData[key] === postData[key]);
        let matchResult = true;
        // check assign feature original list to update list,to check any changes make or not
        if(params?.editRoleData?.assign_features){

            for (let assignFeature of params?.editRoleData?.assign_features) {
                // Check if there is a matching object in postData based on feature_id and access_type
                let matchedPostData = postData.assign_features.find((postFeature: any) =>
                    postFeature.feature_id === assignFeature.feature_id &&
                +postFeature.access_type === +assignFeature.access_type);
                
                // If a matching object is not found, set matchResult to false and break the loop
                if (!matchedPostData) {
                    matchResult = false;
                    break;
                }
            }
        }
        if (await isFormValid(postData)) {
            params.setLoading(true);
            // add extra payload conditionaly
            if (recentAddId?.length > 0 && checkLength === false) {
                // postData.recentAddId = recentAddId;
            }
            if (recentRemoveId?.length > 0 && checkLength === false) {
                // postData.recentRemoveId = recentRemoveId;
            }
            if (recentAddFeature?.length > 0 && matchResult === false) {
                // postData.recentAddFeature = recentAddFeature;
            }
            if (changeRoleName?.length > 0 && isEqual === false) {
                postData.changeRoleName = changeRoleName;
            }
            if (changeStatus?.length > 0 && isEqual === false) {
                postData.changeStatus = changeStatus;
            }

            saveRoleData({requestData:{...postData, status: +postData['status']}}).then((res: any) => {
                if (res && res.status === 200) {
                    toast.success(res.message)
                    setFormData({});
                    showEditPopUp(false);
                    params.getRoleListDetails({});
                } else {
                    formData.status = '1';
                    setFormData(formData);
                }
                params.setLoading(false);
            }).catch((err: Error) => {
                params.setLoading(false);
            })
        }
    }

    const isFormValid = async (formData: any) => {
        let isValid = true;
        if (!formData.role_name || formData.role_name === '') {
            errors['roleName'] = 'Role Name is required';
            isValid = false;
        } else if (formData && formData.role_name && (formData.role_name.split('').length < 3 || formData.role_name.split('').length > 30)) {
            errors['roleName'] = 'Invalid Role Name';
            isValid = false;
        }
        setError({ ...errors });
        return isValid;

    }

    const getFormData = async () => {
        let postData: any = {};
        if (formData && formData.id) postData['id'] = formData.id;
        if (formData && formData.roleName) postData['role_name'] = formData.roleName?.replace(/\s+/g, ' ').trim();
        if (formData && formData.description) postData['role_description'] = formData.description?.replace(/\s+/g, ' ').trim();
        postData['status'] = formData.status ? formData.status : 0;
        postData['assign_user_ids'] = user_list.length ? user_list.filter((f: any) => f.isChecked).map((e: any) => e.value) : [];
        postData['assign_features'] = feature_list.length ? feature_list.filter((f: any) => f.isChecked).map((e: any) => {
            return { feature_id: e.value, access_type: String(e.access_type) }
        }) : [];
        return postData;
    }

    const getUserList = async () => {
        if (roleUserList && roleUserList.length) {
            let userList: any = [];
            let assignedUser: string | string[] = [];

            if (editRoleData && editRoleData.user_ids) {
                assignedUser = editRoleData.user_ids.split(',');
            }
            userList = roleUserList.map((e: any) => {
                let isChecked = (assignedUser && assignedUser.length) ? assignedUser.includes(String(e.id)) : false;

                return {
                    value: e.id,
                    label: e.name,
                    isChecked: isChecked
                }
            });
            setUserList([...userList]);

        }
    };

    const getFeatureList = async () => {
        if (featureList && featureList.length) {
            let roleFeatureList = [];
            roleFeatureList = featureList.map((e: any) => {
                let isFeaturesExist = editRoleData.assign_features && editRoleData.assign_features.length ? _.find(editRoleData.assign_features, { 'feature_id': Number(e.id) }) : false;
                return {
                    value: e.id,
                    label: e.name,
                    access_type: isFeaturesExist ? +isFeaturesExist.access_type : 1,
                    isChecked: isFeaturesExist ? true : false,
                    parent_id: e.parent_id
                }
            })
            setFeatureList([...roleFeatureList]);
        }
    };


    let filteredUserList = useMemo(() => {
        if (formData && formData.search_user) {
            return user_list.filter((e: any) => e.label.toLowerCase().includes(formData.search_user.toLowerCase()))
        }
        return user_list;
        // eslint-disable-next-line
    }, [user_list, formData.search_user]);



    let filteredFeatureList = useMemo(() => {
        if (formData && formData.search_feature) {
            return feature_list.filter((e: any) => e.label.toLowerCase().includes(formData.search_feature.toLowerCase()))
        }
        return feature_list;
        // eslint-disable-next-line
    }, [feature_list, formData.search_feature])


    // remove 'My Cases = 35' and 'Assign Lead to User = 34'
    // const valuesToRemove = [34, 35];
    // const updatedFeatureList:any = filteredFeatureList.filter((feature: any) => !valuesToRemove.includes(feature.value));

    return (
        <React.Fragment>

            {
                showPopup ?
                    <div className="edit-role-outer">
                        <div className="edit-role-main">
                            <h2>Edit Role</h2>
                            <a className="close-icn" onClick={() => showEditPopUp(false)}><i className="ic-clearclose"></i></a>
                            <div className="">
                                <div className="role-status-outert row align-center p-md-b">
                                    <div className="col-md-6">
                                        <fieldset className="form-filed">
                                            <div className="material">
                                                <input id="roleName" type="text" placeholder=" " name="RoleName" className="form-input" onChange={(e: any) => handleChange('roleName', e, '')} value={formData ? formData.roleName : ""} />
                                                <label data-label="Role Name" className="form-label"></label>
                                                <span className="error-txt">
                                                    {errors.roleName || ""}
                                                </span>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className="col-md-6">
                                        <fieldset className="form-filed">
                                            <div className="material status-btn">
                                                <ToggleSwitch checked={+formData.status === 1 ? true : false} onChange={(e: any) => handleChange('status', e, '')} />
                                                <div className="status-txt">{formData.status === 1 ? "Active" : "Inactive"}</div>
                                            </div>

                                        </fieldset>
                                    </div>

                                </div>
                                <div className="m-xl-t row">
                                    <fieldset className="form-filed col-md-12">
                                        <div className="material">
                                            <input id="description" type="text" placeholder=" " name="RoleDescription" className="form-input" onChange={(e: any) => handleChange('description', e, '')} value={formData ? formData.description : ""} />
                                            <label data-label="Role Description" className="form-label"></label>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <Tab.Container id="edit-role-container" defaultActiveKey="first">
                                <div className="selected-chips">
                                    <Nav variant="pills" className="flex-column tab-line nav nav-pills assign-user-tab">
                                        <div className="tab-list">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">
                                                    Assign Users
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second"> Assign Features</Nav.Link>
                                            </Nav.Item>
                                        </div>
                                    </Nav>
                                </div>
                                <Tab.Content id="edit-role-content">
                                    <Tab.Pane eventKey="first">
                                        <div className="row">
                                            <fieldset className="search-bx col-md-12">
                                                <div className="material">
                                                    <input id="user_search" type="text" placeholder="Search User" className="form-input" value={formData ? formData.search_user : ''} onChange={(e: any) => handleChange("search_user", e, '')} />
                                                    <i className="ic-search"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className="user-list">
                                            <ul>
                                                {filteredUserList.length > 0 && filteredUserList.map((user: any, key: number) => (
                                                    <li key={key}>
                                                        <fieldset className="form-filed">
                                                            <div className="material">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input id={user.value} type="checkbox" className="custom-control-input " name={user.label}
                                                                        onChange={(e: any) => handleChange("user_ids", e, user.value)} checked={user.isChecked} />

                                                                    <label className="custom-control-label"> {user.label} </label>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="row">
                                            <fieldset className="search-bx col-md-12">
                                                <div className="material">
                                                    <input id="feature_search" type="text" placeholder="Search Feature" className="form-input" onChange={(e: any) => handleChange("search_feature", e, '')}
                                                        value={formData ? formData.search_feature : ''} />
                                                    <i className="ic-search"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div className="user-list assign-featue-list">
                                            <ul>
                                                {filteredFeatureList.length > 0 && filteredFeatureList.map((feature: any, index: number) => {
                                                    const children = filteredFeatureList.filter((childFeature: any) => childFeature.parent_id === feature.value);
                                                    const hasChildren = children.length > 0;

                                                    return (
                                                        <>
                                                            <li className={`custom-control custom-checkbox`} key={index}>
                                                                {hasChildren ? (
                                                                    <strong>{feature.label}</strong>
                                                                ) : (
                                                                    <>
                                                                        <input id={"feature_" + feature.value} type="checkbox" className="custom-control-input " checked={feature.isChecked}
                                                                            onChange={(e: any) => handleChange("feature_data", e, feature.value)}
                                                                        />
                                                                        <label htmlFor={"feature_" + feature.value} className={`custom-control-label ${feature.parent_id ? 'custom-control-label-child' : ''}`}> {feature.label} </label>
                                                                        {feature.isChecked &&
                                                                            <div className="user-permission">
                                                                                <div className="input-radio">
                                                                                    <input
                                                                                        id={"radio1_" + feature.value}
                                                                                        name={"radio_" + feature.value}
                                                                                        type="radio"
                                                                                        className="custom-control-input "
                                                                                        checked={Number(feature.access_type) === 1}
                                                                                        onChange={(e: any) => handleChange("additional_feature_radio", e, feature.value)}
                                                                                    />
                                                                                    <label htmlFor={"radio1_" + feature.value} className="radio-label">View </label>
                                                                                </div>
                                                                                <div className="input-radio">
                                                                                    <input
                                                                                        id={"radio2_" + feature.value}
                                                                                        name={"radio_" + feature.value}
                                                                                        type="radio"
                                                                                        className="custom-control-input "
                                                                                        checked={Number(feature.access_type) === 2}
                                                                                        onChange={(e: any) => handleChange("additional_feature_radio", e, feature.value)}
                                                                                    />
                                                                                    <label htmlFor={"radio2_" + feature.value} className="radio-label">Can Edit </label>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                )}
                                                            </li>
                                                        </>
                                                    )
                                                })}
                                            </ul>
                                        </div>

                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>

                            {
                                HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['user_role_management'], accessType: 'edit' }) &&  
                                <button className="btn-primary m-md-t" onClick={(e: any) => handleSubmit(e)} >Save Changes</button>
                            }


                        </div>
                    </div>
                    : null
            }
        </React.Fragment>
    )
}

export default RoleListEdit