import React, { useState, useEffect } from "react";
import UploadIcn from "../webroot/images/uplaod-file-icn.svg";
import { DOC_UPLOAD_VALIDATION } from "@src/config/constant";
import { toast } from "react-toastify";
import FullPageLoader from "@src/view/common/FullPageLoader";



export default function FileUpload(props: any) {
    const [uploadFields, setUploadFields] = useState<any>({ file: null, dragOver: false, errorNoficication: null });
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let upFields = { file: null, dragOver: false, errorNoficication: null }
        setUploadFields({ ...upFields });
    }, [props.uploadReset]);

    useEffect(() => {
        const fileBox = document.getElementById(`upload-image-input`) as any;
        fileBox.value = ''
    }, [props.BlankFlage]);

    /**
       Drag and Drop Event Handlers
    **/
    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    }
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (!uploadFields.dragOver) {
            let fields = JSON.parse(JSON.stringify(uploadFields));
            fields.dragOver = true
            setUploadFields({ ...fields });
        }
    }
    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        let fields = JSON.parse(JSON.stringify(uploadFields));
        fields.dragOver = false;
        setUploadFields({ ...fields });
    }
    const handleCancelUpload = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        let fields = JSON.parse(JSON.stringify(uploadFields));
        fields.file = null;
        setUploadFields({ ...fields });
    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        setLoading(true)
        e.preventDefault();
        let file = e.dataTransfer.files[0];
        let fields = JSON.parse(JSON.stringify(uploadFields));
        let fileType = file.type.split("/")[1];

        const maxFileSizeInBytes = DOC_UPLOAD_VALIDATION['maxSize']
        const minFileSize = DOC_UPLOAD_VALIDATION['minSize']

        if (file.size > maxFileSizeInBytes || file.size < minFileSize) {
            fields.file = null;
            fields.errorNotification = "Invalid file size";
            setUploadFields({ ...fields });
            setTimeout(() => {
                fields.errorNotification = null;
                setUploadFields({ ...fields });
            }, 3000);
            return;
        }
        if (!["jpg", "jpeg", "png", "pdf", "doc", "docx"].includes(fileType)) {
            fields.dragOver = false;
            fields.errorNotification = "Not an image File";
            fields.file = null
            setUploadFields({ ...fields });
            return setTimeout(() => {
                fields.errorNotification = null;
                setUploadFields({ ...fields });

            }, 3000);
        }


        fields.file = file
        fields.dragOver = false;
        setUploadFields({ ...fields });
        props.setImageValue(fields.file);
        setLoading(false)
    }

    /**
       Handle Manually (File Input) Added Files
    **/
    const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true)
        e.preventDefault();
        let upfields: any = {};
        const file: any = e.target.files?.[0];
        const fileType: string = file && file.type.split('/')[1];

        const maxFileSizeInBytes = DOC_UPLOAD_VALIDATION['maxSize']
        const minFileSize = DOC_UPLOAD_VALIDATION['minSize']

        if (file.size > maxFileSizeInBytes || file.size < minFileSize) {
            upfields.file = null;
            upfields.errorNotification = "Invalid file size";
            toast.error(upfields.errorNotification)
            setUploadFields({ ...upfields });
            setTimeout(() => {
                upfields.errorNotification = null;
                setUploadFields({ ...upfields });
            }, 3000);
            setLoading(false)
            return;
        }

        // Validate file is of type Image

        if (!["jpg", "jpeg", "png", "pdf", "doc", "docx"].includes(fileType)) {
            console.log("Not an image file");
            upfields.file = null;
            upfields.errorNotification = "Not an image File";
            upfields.dragOverClass = ""

            toast.error(upfields.errorNotification)

            setLoading(false)
            return setTimeout(() => {
                upfields.errorNotification = "";
                setUploadFields({ ...upfields });
            }, 3000);
        }
        upfields.file = file;
        props.setError(false)
        setUploadFields({ ...upfields });

        props.setImageValue(upfields.file);
        setLoading(false)
    }

    /**
    Handle Upload after Upload Button Clicked
    **/
    const handleUploadImage = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (uploadFields.file) {
            props.setImageValue(uploadFields.file);
        }
    }

    const dragOverClass = uploadFields?.dragOver ? "display-box drag-over" : "display-box";

    const uploadText = uploadFields?.file ? (
        <div className="upload-file-name">
            {/* <span className="suceess-icn">
                <i className="ic-check"></i>
            </span> */}
            <h4>{uploadFields?.file?.name}</h4>
            {/* <i className="ic-clearclose"></i> */}
            <button className="upload-button btn btn-primary" onClick={handleUploadImage}>
                Browse Another File
            </button>
        </div>
    ) : (
        <div className="upload-txt">
            <h4>Drop Files Here to Upload</h4>
            <div className="or-txt">or</div>
            <button className="btn-primary file-btn">Browse File</button>
        </div>
    );

    const errorNotificationElement = uploadFields?.errorNotification ? (
        <div className="error-notification">
            <p>{uploadFields?.errorNotification}</p>
        </div>
    ) : null;

    return (
        <>
            <FullPageLoader show={loading} />
            <div style={{ textAlign: "center" }}>
                <div>
                    <div className="image-uploader-wrapper">
                        <div className={dragOverClass} onDragEnter={handleDragEnter} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
                            <div className="icon-text-box">
                                <div className="upload-icon">
                                    <img src={UploadIcn} />
                                </div>
                                <div className="upload-text">
                                    {uploadText}
                                </div>

                                {errorNotificationElement}
                            </div>
                            <div>
                                <input
                                    type="file"
                                    id="upload-image-input"
                                    className="upload-image-input"
                                    accept="image/*"
                                    onDrop={handleDrop}
                                    // onDragEnter={(e:any)=>{handleDragEnter(e)}}
                                    // onDragOver={(e:any)=>{handleDragOver(e)}}
                                    // onDragLeave={handleDragLeave}
                                    onChange={handleAddImage}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        // (uploadFields.file)?
                        // <div className="col-md-12 p-lg-t">
                        //     <button className="btn-primary" onClick={handleUploadImage}>upload File</button>
                        // </div>:
                        // <div className="col-md-12 p-lg-t">
                        //     <button className="btn-primary" disabled>upload File</button>
                        // </div>  
                    }

                </div>
            </div>
        </>
    );

}